<template>
<div id="app">
    <div id="proof" class="proofmain showclass" v-if="isShow">
        <div class="datimage">
            <img :src="'https://asuwllkdfj.s3.us-east-2.amazonaws.com/maps/'+imaddr+'-min.PNG'">
        </div>
        <div class="content">
            <div class="who">{{fname}} <br>{{saddress}}</div>
            <div>
                <div class="what">Pre-Qualified for Yara Weight Loss Program</div>
                <div class="when">{{stime}} minutes ago</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import laname from './utilits/lname.js'
import faname from './utilits/fnames.js'
import places from './utilits/places.js'

export default {
    data() {
        return {
            city: places,
            firstname: faname,
            lastname: laname,
            fname: '',
            lname: '',
            saddress: '',
            stime: '',
            shcls: '',
            imaddr: '',
            isShow: false,
            compute: true,
        }
    },
    mounted: function () {

        var self = this;
        
        setInterval(function () {
            self.setshow();

        }, 10000);

    },
    methods: {
        setshow() {
            var self = this;
            let t = Math.floor(Math.random() * 1000);
            if (self.compute == false) {
                self.isShow = false;
                self.doAdelay(t);
            }
            if (self.isShow == false) {
                self.doAdelay(t);
                let f = Math.floor(Math.random() * 1000);
                let l = Math.floor(Math.random() * 1000);
                let a = Math.floor(Math.random() * 1000);
                self.lname = self.lastname[l];
                self.fname = self.firstname[f];
                self.saddress = self.city[a]['city'] + ", " + self.city[a]['abbr'];
                self.imaddr = self.city[a]['state'];
                self.stime = Math.floor(Math.random() * 60);

                self.isShow = true;
                self.shcls = "showclass"
                self.compute = true;
            } else {
                self.isShow = false;
                self.shcls = "hideclass"
                self.compute = false;
            }

        },

        doAdelay(t) {
            setTimeout(function () {
                return true;
            }, t * 1000);
        },
    }
}
</script>

<style>

</style>
