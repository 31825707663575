const data = 
[
  {
    "city": "Mountain View",
    "state": "California",
    "lat": 19.5358,
    "lng": -155.1595,
    "abbr": "CA"
  },
  {
    "city": "Honolulu",
    "state": "Hawaii",
    "lat": 21.3293,
    "lng": -157.846,
    "abbr": "HI"
  },
  {
    "city": "Layton",
    "state": "Utah",
    "lat": 24.8246,
    "lng": -80.8128,
    "abbr": "UT"
  },
  {
    "city": "Homestead",
    "state": "Florida",
    "lat": 25.4664,
    "lng": -80.4472,
    "abbr": "FL"
  },
  {
    "city": "Cutler Bay",
    "state": "Florida",
    "lat": 25.5765,
    "lng": -80.3357,
    "abbr": "FL"
  },
  {
    "city": "Coral Gables",
    "state": "Florida",
    "lat": 25.7037,
    "lng": -80.2715,
    "abbr": "FL"
  },
  {
    "city": "Miami",
    "state": "Florida",
    "lat": 25.784,
    "lng": -80.2102,
    "abbr": "FL"
  },
  {
    "city": "Doral",
    "state": "Florida",
    "lat": 25.815,
    "lng": -80.3565,
    "abbr": "FL"
  },
  {
    "city": "Miami Beach",
    "state": "Florida",
    "lat": 25.8171,
    "lng": -80.1396,
    "abbr": "FL"
  },
  {
    "city": "Hialeah",
    "state": "Florida",
    "lat": 25.8696,
    "lng": -80.3045,
    "abbr": "FL"
  },
  {
    "city": "North Miami",
    "state": "Florida",
    "lat": 25.9008,
    "lng": -80.1686,
    "abbr": "FL"
  },
  {
    "city": "North Miami Beach",
    "state": "Florida",
    "lat": 25.9302,
    "lng": -80.166,
    "abbr": "FL"
  },
  {
    "city": "Miami Gardens",
    "state": "Florida",
    "lat": 25.9433,
    "lng": -80.2425,
    "abbr": "FL"
  },
  {
    "city": "Aventura",
    "state": "Florida",
    "lat": 25.9566,
    "lng": -80.1371,
    "abbr": "FL"
  },
  {
    "city": "Miramar",
    "state": "Florida",
    "lat": 25.9773,
    "lng": -80.3357,
    "abbr": "FL"
  },
  {
    "city": "Hallandale Beach",
    "state": "Florida",
    "lat": 25.9854,
    "lng": -80.1423,
    "abbr": "FL"
  },
  {
    "city": "Pembroke Pines",
    "state": "Florida",
    "lat": 26.0127,
    "lng": -80.3384,
    "abbr": "FL"
  },
  {
    "city": "Hollywood",
    "state": "Florida",
    "lat": 26.0293,
    "lng": -80.1679,
    "abbr": "FL"
  },
  {
    "city": "Santa Maria",
    "state": "California",
    "lat": 26.0767,
    "lng": -97.8463,
    "abbr": "CA"
  },
  {
    "city": "Davie",
    "state": "Florida",
    "lat": 26.0789,
    "lng": -80.287,
    "abbr": "FL"
  },
  {
    "city": "Plantation",
    "state": "Florida",
    "lat": 26.126,
    "lng": -80.2617,
    "abbr": "FL"
  },
  {
    "city": "Fort Lauderdale",
    "state": "Florida",
    "lat": 26.1412,
    "lng": -80.1464,
    "abbr": "FL"
  },
  {
    "city": "Sunrise",
    "state": "Florida",
    "lat": 26.1547,
    "lng": -80.2998,
    "abbr": "FL"
  },
  {
    "city": "Lauderhill",
    "state": "Florida",
    "lat": 26.1605,
    "lng": -80.2241,
    "abbr": "FL"
  },
  {
    "city": "Weslaco",
    "state": "Texas",
    "lat": 26.1669,
    "lng": -97.9859,
    "abbr": "TX"
  },
  {
    "city": "Pharr",
    "state": "Texas",
    "lat": 26.1687,
    "lng": -98.1904,
    "abbr": "TX"
  },
  {
    "city": "Oakland Park",
    "state": "Florida",
    "lat": 26.1779,
    "lng": -80.1527,
    "abbr": "FL"
  },
  {
    "city": "Mission",
    "state": "Texas",
    "lat": 26.204,
    "lng": -98.3251,
    "abbr": "TX"
  },
  {
    "city": "Tamarac",
    "state": "Florida",
    "lat": 26.2057,
    "lng": -80.2547,
    "abbr": "FL"
  },
  {
    "city": "North Lauderdale",
    "state": "Florida",
    "lat": 26.2113,
    "lng": -80.2209,
    "abbr": "FL"
  },
  {
    "city": "McAllen",
    "state": "Texas",
    "lat": 26.2203,
    "lng": -98.2457,
    "abbr": "TX"
  },
  {
    "city": "Pompano Beach",
    "state": "Florida",
    "lat": 26.2428,
    "lng": -80.1312,
    "abbr": "FL"
  },
  {
    "city": "Margate",
    "state": "Florida",
    "lat": 26.2465,
    "lng": -80.2119,
    "abbr": "FL"
  },
  {
    "city": "Coral Springs",
    "state": "Florida",
    "lat": 26.2702,
    "lng": -80.2593,
    "abbr": "FL"
  },
  {
    "city": "Coconut Creek",
    "state": "Florida",
    "lat": 26.2803,
    "lng": -80.1842,
    "abbr": "FL"
  },
  {
    "city": "Deerfield Beach",
    "state": "Florida",
    "lat": 26.305,
    "lng": -80.1278,
    "abbr": "FL"
  },
  {
    "city": "Bonita Springs",
    "state": "Florida",
    "lat": 26.3557,
    "lng": -81.7859,
    "abbr": "FL"
  },
  {
    "city": "Santa Monica",
    "state": "California",
    "lat": 26.3654,
    "lng": -97.59,
    "abbr": "CA"
  },
  {
    "city": "Boca Raton",
    "state": "Florida",
    "lat": 26.3749,
    "lng": -80.1078,
    "abbr": "FL"
  },
  {
    "city": "Delray Beach",
    "state": "Florida",
    "lat": 26.455,
    "lng": -80.0905,
    "abbr": "FL"
  },
  {
    "city": "Boynton Beach",
    "state": "Florida",
    "lat": 26.5282,
    "lng": -80.0812,
    "abbr": "FL"
  },
  {
    "city": "Fort Myers",
    "state": "Florida",
    "lat": 26.6197,
    "lng": -81.8306,
    "abbr": "FL"
  },
  {
    "city": "Palm Springs",
    "state": "California",
    "lat": 26.6372,
    "lng": -80.0961,
    "abbr": "CA"
  },
  {
    "city": "Cape Coral",
    "state": "Florida",
    "lat": 26.644,
    "lng": -81.9961,
    "abbr": "FL"
  },
  {
    "city": "Haverhill",
    "state": "Massachusetts",
    "lat": 26.6909,
    "lng": -80.1218,
    "abbr": "MA"
  },
  {
    "city": "West Palm Beach",
    "state": "Florida",
    "lat": 26.7468,
    "lng": -80.1315,
    "abbr": "FL"
  },
  {
    "city": "Palm Beach Gardens",
    "state": "Florida",
    "lat": 26.8489,
    "lng": -80.1654,
    "abbr": "FL"
  },
  {
    "city": "Grove City",
    "state": "Ohio",
    "lat": 26.9071,
    "lng": -82.3258,
    "abbr": "OH"
  },
  {
    "city": "Jupiter",
    "state": "Florida",
    "lat": 26.9195,
    "lng": -80.1123,
    "abbr": "FL"
  },
  {
    "city": "Palmdale",
    "state": "California",
    "lat": 26.9453,
    "lng": -81.3162,
    "abbr": "CA"
  },
  {
    "city": "North Port",
    "state": "Florida",
    "lat": 27.0577,
    "lng": -82.1976,
    "abbr": "FL"
  },
  {
    "city": "Sarasota",
    "state": "Florida",
    "lat": 27.3391,
    "lng": -82.5439,
    "abbr": "FL"
  },
  {
    "city": "Fort Pierce",
    "state": "Florida",
    "lat": 27.4263,
    "lng": -80.3421,
    "abbr": "FL"
  },
  {
    "city": "Bradenton",
    "state": "Florida",
    "lat": 27.49,
    "lng": -82.5746,
    "abbr": "FL"
  },
  {
    "city": "Memphis",
    "state": "Tennessee",
    "lat": 27.5435,
    "lng": -82.5607,
    "abbr": "TN"
  },
  {
    "city": "Laredo",
    "state": "Texas",
    "lat": 27.5536,
    "lng": -99.489,
    "abbr": "TX"
  },
  {
    "city": "Corpus Christi",
    "state": "Texas",
    "lat": 27.7173,
    "lng": -97.3822,
    "abbr": "TX"
  },
  {
    "city": "Gulfport",
    "state": "Mississippi",
    "lat": 27.7463,
    "lng": -82.7099,
    "abbr": "MS"
  },
  {
    "city": "San Diego",
    "state": "California",
    "lat": 27.7609,
    "lng": -98.2389,
    "abbr": "CA"
  },
  {
    "city": "Pinellas Park",
    "state": "Florida",
    "lat": 27.8586,
    "lng": -82.7075,
    "abbr": "FL"
  },
  {
    "city": "Largo",
    "state": "Florida",
    "lat": 27.9088,
    "lng": -82.7714,
    "abbr": "FL"
  },
  {
    "city": "Clearwater",
    "state": "Florida",
    "lat": 27.9787,
    "lng": -82.7623,
    "abbr": "FL"
  },
  {
    "city": "Palm Bay",
    "state": "Florida",
    "lat": 27.9861,
    "lng": -80.6628,
    "abbr": "FL"
  },
  {
    "city": "Tampa",
    "state": "Florida",
    "lat": 27.9937,
    "lng": -82.4454,
    "abbr": "FL"
  },
  {
    "city": "Crystal Lake",
    "state": "Illinois",
    "lat": 28.0371,
    "lng": -81.9063,
    "abbr": "IL"
  },
  {
    "city": "Lakeland",
    "state": "Florida",
    "lat": 28.0556,
    "lng": -81.9542,
    "abbr": "FL"
  },
  {
    "city": "Providence",
    "state": "Rhode Island",
    "lat": 28.1539,
    "lng": -81.9734,
    "abbr": "RI"
  },
  {
    "city": "Campbell",
    "state": "California",
    "lat": 28.2603,
    "lng": -81.4525,
    "abbr": "CA"
  },
  {
    "city": "Kissimmee",
    "state": "Florida",
    "lat": 28.3042,
    "lng": -81.4164,
    "abbr": "FL"
  },
  {
    "city": "San Antonio",
    "state": "Texas",
    "lat": 28.3396,
    "lng": -82.279,
    "abbr": "TX"
  },
  {
    "city": "Los Angeles",
    "state": "California",
    "lat": 28.4658,
    "lng": -99,
    "abbr": "CA"
  },
  {
    "city": "Garden Grove",
    "state": "California",
    "lat": 28.4716,
    "lng": -82.4348,
    "abbr": "CA"
  },
  {
    "city": "Berkeley",
    "state": "California",
    "lat": 28.5028,
    "lng": -82.5887,
    "abbr": "CA"
  },
  {
    "city": "Winter Garden",
    "state": "Florida",
    "lat": 28.543,
    "lng": -81.5944,
    "abbr": "FL"
  },
  {
    "city": "High Point",
    "state": "North Carolina",
    "lat": 28.5474,
    "lng": -82.5206,
    "abbr": "NC"
  },
  {
    "city": "Oakland",
    "state": "California",
    "lat": 28.5538,
    "lng": -81.6335,
    "abbr": "CA"
  },
  {
    "city": "Titusville",
    "state": "Florida",
    "lat": 28.5725,
    "lng": -80.8193,
    "abbr": "FL"
  },
  {
    "city": "Ocoee",
    "state": "Florida",
    "lat": 28.5785,
    "lng": -81.5336,
    "abbr": "FL"
  },
  {
    "city": "Altamonte Springs",
    "state": "Florida",
    "lat": 28.6616,
    "lng": -81.395,
    "abbr": "FL"
  },
  {
    "city": "Apopka",
    "state": "Florida",
    "lat": 28.7006,
    "lng": -81.5303,
    "abbr": "FL"
  },
  {
    "city": "Charlotte",
    "state": "North Carolina",
    "lat": 28.8593,
    "lng": -98.7006,
    "abbr": "NC"
  },
  {
    "city": "Grand Island",
    "state": "Nebraska",
    "lat": 28.8825,
    "lng": -81.729,
    "abbr": "NE"
  },
  {
    "city": "Deltona",
    "state": "Florida",
    "lat": 28.9051,
    "lng": -81.2134,
    "abbr": "FL"
  },
  {
    "city": "Moore",
    "state": "Oklahoma",
    "lat": 29.0574,
    "lng": -99.0068,
    "abbr": "OK"
  },
  {
    "city": "Port Orange",
    "state": "Florida",
    "lat": 29.1084,
    "lng": -81.0136,
    "abbr": "FL"
  },
  {
    "city": "Ocala",
    "state": "Florida",
    "lat": 29.1803,
    "lng": -82.1487,
    "abbr": "FL"
  },
  {
    "city": "Daytona Beach",
    "state": "Florida",
    "lat": 29.1959,
    "lng": -81.0935,
    "abbr": "FL"
  },
  {
    "city": "Galveston",
    "state": "Texas",
    "lat": 29.2487,
    "lng": -94.891,
    "abbr": "TX"
  },
  {
    "city": "Ormond Beach",
    "state": "Florida",
    "lat": 29.2951,
    "lng": -81.0999,
    "abbr": "FL"
  },
  {
    "city": "Texas City",
    "state": "Texas",
    "lat": 29.4127,
    "lng": -94.9658,
    "abbr": "TX"
  },
  {
    "city": "Raleigh",
    "state": "North Carolina",
    "lat": 29.4479,
    "lng": -82.4681,
    "abbr": "NC"
  },
  {
    "city": "League City",
    "state": "Texas",
    "lat": 29.4873,
    "lng": -95.109,
    "abbr": "TX"
  },
  {
    "city": "Friendswood",
    "state": "Texas",
    "lat": 29.5109,
    "lng": -95.198,
    "abbr": "TX"
  },
  {
    "city": "Fresno",
    "state": "California",
    "lat": 29.5357,
    "lng": -95.4694,
    "abbr": "CA"
  },
  {
    "city": "Palm Coast",
    "state": "Florida",
    "lat": 29.5403,
    "lng": -81.2468,
    "abbr": "FL"
  },
  {
    "city": "Pearland",
    "state": "Texas",
    "lat": 29.5585,
    "lng": -95.3201,
    "abbr": "TX"
  },
  {
    "city": "Missouri City",
    "state": "Texas",
    "lat": 29.5634,
    "lng": -95.5377,
    "abbr": "TX"
  },
  {
    "city": "Hawthorne",
    "state": "California",
    "lat": 29.588,
    "lng": -82.0842,
    "abbr": "CA"
  },
  {
    "city": "Sugar Land",
    "state": "Texas",
    "lat": 29.5956,
    "lng": -95.6283,
    "abbr": "TX"
  },
  {
    "city": "Trenton",
    "state": "New Jersey",
    "lat": 29.6094,
    "lng": -82.8147,
    "abbr": "NJ"
  },
  {
    "city": "Phoenix",
    "state": "Arizona",
    "lat": 29.646,
    "lng": -89.9398,
    "abbr": "AZ"
  },
  {
    "city": "Pasadena",
    "state": "California",
    "lat": 29.6583,
    "lng": -95.1498,
    "abbr": "CA"
  },
  {
    "city": "Pasadena",
    "state": "Texas",
    "lat": 29.6583,
    "lng": -95.1498,
    "abbr": "TX"
  },
  {
    "city": "New Braunfels",
    "state": "Texas",
    "lat": 29.6995,
    "lng": -98.1152,
    "abbr": "TX"
  },
  {
    "city": "Baytown",
    "state": "Texas",
    "lat": 29.7607,
    "lng": -94.9628,
    "abbr": "TX"
  },
  {
    "city": "Port Arthur",
    "state": "Texas",
    "lat": 29.8554,
    "lng": -93.9264,
    "abbr": "TX"
  },
  {
    "city": "San Marcos",
    "state": "California",
    "lat": 29.8766,
    "lng": -97.9311,
    "abbr": "CA"
  },
  {
    "city": "San Marcos",
    "state": "Texas",
    "lat": 29.8766,
    "lng": -97.9311,
    "abbr": "TX"
  },
  {
    "city": "Santa Fe",
    "state": "New Mexico",
    "lat": 29.8836,
    "lng": -82.4304,
    "abbr": "NM"
  },
  {
    "city": "Avondale",
    "state": "Arizona",
    "lat": 29.9072,
    "lng": -90.1933,
    "abbr": "AZ"
  },
  {
    "city": "Kenner",
    "state": "Louisiana",
    "lat": 30.0109,
    "lng": -90.2549,
    "abbr": "LA"
  },
  {
    "city": "Ames",
    "state": "Iowa",
    "lat": 30.0451,
    "lng": -94.7373,
    "abbr": "IA"
  },
  {
    "city": "New Orleans",
    "state": "Louisiana",
    "lat": 30.0687,
    "lng": -89.9288,
    "abbr": "LA"
  },
  {
    "city": "Beaumont",
    "state": "California",
    "lat": 30.0849,
    "lng": -94.1451,
    "abbr": "CA"
  },
  {
    "city": "Beaumont",
    "state": "Texas",
    "lat": 30.0849,
    "lng": -94.1451,
    "abbr": "TX"
  },
  {
    "city": "Hempstead",
    "state": "New York",
    "lat": 30.0975,
    "lng": -96.0795,
    "abbr": "NY"
  },
  {
    "city": "Panama City",
    "state": "Florida",
    "lat": 30.1985,
    "lng": -85.6153,
    "abbr": "FL"
  },
  {
    "city": "Lake Charles",
    "state": "Louisiana",
    "lat": 30.203,
    "lng": -93.215,
    "abbr": "LA"
  },
  {
    "city": "Johnson City",
    "state": "Tennessee",
    "lat": 30.2742,
    "lng": -98.4063,
    "abbr": "TN"
  },
  {
    "city": "Austin",
    "state": "Texas",
    "lat": 30.3038,
    "lng": -97.7545,
    "abbr": "TX"
  },
  {
    "city": "Conroe",
    "state": "Texas",
    "lat": 30.3174,
    "lng": -95.4705,
    "abbr": "TX"
  },
  {
    "city": "Jacksonville",
    "state": "Florida",
    "lat": 30.3322,
    "lng": -81.6749,
    "abbr": "FL"
  },
  {
    "city": "Jacksonville",
    "state": "North Carolina",
    "lat": 30.3322,
    "lng": -81.6749,
    "abbr": "NC"
  },
  {
    "city": "Elgin",
    "state": "Illinois",
    "lat": 30.3529,
    "lng": -97.3887,
    "abbr": "IL"
  },
  {
    "city": "Sandy",
    "state": "Utah",
    "lat": 30.3608,
    "lng": -98.4695,
    "abbr": "UT"
  },
  {
    "city": "Youngstown",
    "state": "Ohio",
    "lat": 30.3644,
    "lng": -85.4383,
    "abbr": "OH"
  },
  {
    "city": "Biloxi",
    "state": "Mississippi",
    "lat": 30.4393,
    "lng": -88.9542,
    "abbr": "MS"
  },
  {
    "city": "Baton Rouge",
    "state": "Louisiana",
    "lat": 30.4422,
    "lng": -91.1314,
    "abbr": "LA"
  },
  {
    "city": "Pensacola",
    "state": "Florida",
    "lat": 30.4427,
    "lng": -87.1886,
    "abbr": "FL"
  },
  {
    "city": "Pflugerville",
    "state": "Texas",
    "lat": 30.4544,
    "lng": -97.6053,
    "abbr": "TX"
  },
  {
    "city": "Tallahassee",
    "state": "Florida",
    "lat": 30.4549,
    "lng": -84.2527,
    "abbr": "FL"
  },
  {
    "city": "Freeport",
    "state": "New York",
    "lat": 30.503,
    "lng": -86.1409,
    "abbr": "NY"
  },
  {
    "city": "Portland",
    "state": "Maine",
    "lat": 30.5124,
    "lng": -86.1958,
    "abbr": "ME"
  },
  {
    "city": "Portland",
    "state": "Oregon",
    "lat": 30.5124,
    "lng": -86.1958,
    "abbr": "OR"
  },
  {
    "city": "Cedar Park",
    "state": "Texas",
    "lat": 30.5133,
    "lng": -97.818,
    "abbr": "TX"
  },
  {
    "city": "Round Rock",
    "state": "Texas",
    "lat": 30.5253,
    "lng": -97.6659,
    "abbr": "TX"
  },
  {
    "city": "Livonia",
    "state": "Michigan",
    "lat": 30.5623,
    "lng": -91.5502,
    "abbr": "MI"
  },
  {
    "city": "Greensboro",
    "state": "North Carolina",
    "lat": 30.5704,
    "lng": -84.7327,
    "abbr": "NC"
  },
  {
    "city": "College Station",
    "state": "Texas",
    "lat": 30.5857,
    "lng": -96.296,
    "abbr": "TX"
  },
  {
    "city": "Warren",
    "state": "Michigan",
    "lat": 30.6127,
    "lng": -94.4104,
    "abbr": "MI"
  },
  {
    "city": "Warren",
    "state": "Ohio",
    "lat": 30.6127,
    "lng": -94.4104,
    "abbr": "OH"
  },
  {
    "city": "Bryan",
    "state": "Texas",
    "lat": 30.6661,
    "lng": -96.367,
    "abbr": "TX"
  },
  {
    "city": "Mobile",
    "state": "Alabama",
    "lat": 30.6782,
    "lng": -88.1161,
    "abbr": "AL"
  },
  {
    "city": "Cypress",
    "state": "California",
    "lat": 30.7132,
    "lng": -85.0738,
    "abbr": "CA"
  },
  {
    "city": "Valdosta",
    "state": "Georgia",
    "lat": 30.8503,
    "lng": -83.2789,
    "abbr": "GA"
  },
  {
    "city": "Bakersfield",
    "state": "California",
    "lat": 30.8913,
    "lng": -102.2979,
    "abbr": "CA"
  },
  {
    "city": "Rogers",
    "state": "Arkansas",
    "lat": 30.93,
    "lng": -97.2289,
    "abbr": "AR"
  },
  {
    "city": "Kentwood",
    "state": "Michigan",
    "lat": 30.9339,
    "lng": -90.5152,
    "abbr": "MI"
  },
  {
    "city": "Fullerton",
    "state": "California",
    "lat": 30.9932,
    "lng": -92.9821,
    "abbr": "CA"
  },
  {
    "city": "Killeen",
    "state": "Texas",
    "lat": 31.0758,
    "lng": -97.7286,
    "abbr": "TX"
  },
  {
    "city": "Temple",
    "state": "Texas",
    "lat": 31.1077,
    "lng": -97.3899,
    "abbr": "TX"
  },
  {
    "city": "Dothan",
    "state": "Alabama",
    "lat": 31.2335,
    "lng": -85.4066,
    "abbr": "AL"
  },
  {
    "city": "Hattiesburg",
    "state": "Mississippi",
    "lat": 31.3073,
    "lng": -89.3169,
    "abbr": "MS"
  },
  {
    "city": "San Angelo",
    "state": "Texas",
    "lat": 31.4424,
    "lng": -100.4503,
    "abbr": "TX"
  },
  {
    "city": "Rock Hill",
    "state": "South Carolina",
    "lat": 31.4427,
    "lng": -92.5695,
    "abbr": "SC"
  },
  {
    "city": "Waco",
    "state": "Texas",
    "lat": 31.5597,
    "lng": -97.1882,
    "abbr": "TX"
  },
  {
    "city": "Sierra Vista",
    "state": "Arizona",
    "lat": 31.5631,
    "lng": -110.3153,
    "abbr": "AZ"
  },
  {
    "city": "Jonesboro",
    "state": "Arkansas",
    "lat": 31.6146,
    "lng": -97.8767,
    "abbr": "AR"
  },
  {
    "city": "Elkhart",
    "state": "Indiana",
    "lat": 31.6276,
    "lng": -95.5789,
    "abbr": "IN"
  },
  {
    "city": "Sparks",
    "state": "Nevada",
    "lat": 31.6727,
    "lng": -106.2399,
    "abbr": "NV"
  },
  {
    "city": "Summerville",
    "state": "South Carolina",
    "lat": 31.7554,
    "lng": -92.1624,
    "abbr": "SC"
  },
  {
    "city": "El Paso",
    "state": "Texas",
    "lat": 31.8478,
    "lng": -106.431,
    "abbr": "TX"
  },
  {
    "city": "Sumter",
    "state": "South Carolina",
    "lat": 31.9493,
    "lng": -84.2549,
    "abbr": "SC"
  },
  {
    "city": "Carmel",
    "state": "Indiana",
    "lat": 32.0874,
    "lng": -93.6207,
    "abbr": "IN"
  },
  {
    "city": "La Mesa",
    "state": "California",
    "lat": 32.1244,
    "lng": -106.709,
    "abbr": "CA"
  },
  {
    "city": "Tucson",
    "state": "Arizona",
    "lat": 32.1558,
    "lng": -110.8777,
    "abbr": "AZ"
  },
  {
    "city": "Mesquite",
    "state": "Texas",
    "lat": 32.1669,
    "lng": -106.6885,
    "abbr": "TX"
  },
  {
    "city": "New York",
    "state": "New York",
    "lat": 32.1679,
    "lng": -95.6691,
    "abbr": "NY"
  },
  {
    "city": "Hilton Head Island",
    "state": "South Carolina",
    "lat": 32.1896,
    "lng": -80.7499,
    "abbr": "SC"
  },
  {
    "city": "Flint",
    "state": "Michigan",
    "lat": 32.204,
    "lng": -95.3486,
    "abbr": "MI"
  },
  {
    "city": "Chandler",
    "state": "Arizona",
    "lat": 32.3065,
    "lng": -95.4781,
    "abbr": "AZ"
  },
  {
    "city": "Las Cruces",
    "state": "New Mexico",
    "lat": 32.3265,
    "lng": -106.7893,
    "abbr": "NM"
  },
  {
    "city": "Carlsbad",
    "state": "California",
    "lat": 32.4064,
    "lng": -104.2385,
    "abbr": "CA"
  },
  {
    "city": "Oro Valley",
    "state": "Arizona",
    "lat": 32.4226,
    "lng": -110.9767,
    "abbr": "AZ"
  },
  {
    "city": "Marana",
    "state": "Arizona",
    "lat": 32.4355,
    "lng": -111.1558,
    "abbr": "AZ"
  },
  {
    "city": "Abilene",
    "state": "Texas",
    "lat": 32.4543,
    "lng": -99.7384,
    "abbr": "TX"
  },
  {
    "city": "Phenix City",
    "state": "Alabama",
    "lat": 32.4586,
    "lng": -85.025,
    "abbr": "AL"
  },
  {
    "city": "Shreveport",
    "state": "Louisiana",
    "lat": 32.4659,
    "lng": -93.7959,
    "abbr": "LA"
  },
  {
    "city": "Burleson",
    "state": "Texas",
    "lat": 32.52,
    "lng": -97.3341,
    "abbr": "TX"
  },
  {
    "city": "Bossier City",
    "state": "Louisiana",
    "lat": 32.5227,
    "lng": -93.6665,
    "abbr": "LA"
  },
  {
    "city": "Warner Robins",
    "state": "Georgia",
    "lat": 32.5981,
    "lng": -83.6528,
    "abbr": "GA"
  },
  {
    "city": "DeSoto",
    "state": "Texas",
    "lat": 32.5992,
    "lng": -96.8634,
    "abbr": "TX"
  },
  {
    "city": "Chula Vista",
    "state": "California",
    "lat": 32.6281,
    "lng": -117.0144,
    "abbr": "CA"
  },
  {
    "city": "Duncanville",
    "state": "Texas",
    "lat": 32.646,
    "lng": -96.9127,
    "abbr": "TX"
  },
  {
    "city": "National City",
    "state": "California",
    "lat": 32.6654,
    "lng": -117.0983,
    "abbr": "CA"
  },
  {
    "city": "Calexico",
    "state": "California",
    "lat": 32.6849,
    "lng": -115.4944,
    "abbr": "CA"
  },
  {
    "city": "Grand Prairie",
    "state": "Texas",
    "lat": 32.6869,
    "lng": -97.0209,
    "abbr": "TX"
  },
  {
    "city": "Lawrence",
    "state": "Indiana",
    "lat": 32.7424,
    "lng": -96.3428,
    "abbr": "IN"
  },
  {
    "city": "Lawrence",
    "state": "Kansas",
    "lat": 32.7424,
    "lng": -96.3428,
    "abbr": "KS"
  },
  {
    "city": "Lawrence",
    "state": "Massachusetts",
    "lat": 32.7424,
    "lng": -96.3428,
    "abbr": "MA"
  },
  {
    "city": "Sherman",
    "state": "Texas",
    "lat": 32.7467,
    "lng": -107.8864,
    "abbr": "TX"
  },
  {
    "city": "Santa Clara",
    "state": "California",
    "lat": 32.7778,
    "lng": -108.1555,
    "abbr": "CA"
  },
  {
    "city": "Fort Worth",
    "state": "Texas",
    "lat": 32.7813,
    "lng": -97.3466,
    "abbr": "TX"
  },
  {
    "city": "El Centro",
    "state": "California",
    "lat": 32.7873,
    "lng": -115.5578,
    "abbr": "CA"
  },
  {
    "city": "Sacramento",
    "state": "California",
    "lat": 32.7917,
    "lng": -105.5723,
    "abbr": "CA"
  },
  {
    "city": "Sunnyvale",
    "state": "California",
    "lat": 32.7974,
    "lng": -96.5576,
    "abbr": "CA"
  },
  {
    "city": "El Cajon",
    "state": "California",
    "lat": 32.8017,
    "lng": -116.9604,
    "abbr": "CA"
  },
  {
    "city": "Haltom City",
    "state": "Texas",
    "lat": 32.8176,
    "lng": -97.2707,
    "abbr": "TX"
  },
  {
    "city": "Glenview",
    "state": "Illinois",
    "lat": 32.8323,
    "lng": -116.9059,
    "abbr": "IL"
  },
  {
    "city": "Hurst",
    "state": "Texas",
    "lat": 32.8353,
    "lng": -97.1808,
    "abbr": "TX"
  },
  {
    "city": "Euless",
    "state": "Texas",
    "lat": 32.8507,
    "lng": -97.0798,
    "abbr": "TX"
  },
  {
    "city": "Irving",
    "state": "Texas",
    "lat": 32.8584,
    "lng": -96.9702,
    "abbr": "TX"
  },
  {
    "city": "North Richland Hills",
    "state": "Texas",
    "lat": 32.8605,
    "lng": -97.218,
    "abbr": "TX"
  },
  {
    "city": "Saginaw",
    "state": "Michigan",
    "lat": 32.8649,
    "lng": -97.3649,
    "abbr": "MI"
  },
  {
    "city": "Casa Grande",
    "state": "Arizona",
    "lat": 32.907,
    "lng": -111.7623,
    "abbr": "AZ"
  },
  {
    "city": "North Charleston",
    "state": "South Carolina",
    "lat": 32.9086,
    "lng": -80.0705,
    "abbr": "SC"
  },
  {
    "city": "Rowlett",
    "state": "Texas",
    "lat": 32.9165,
    "lng": -96.5494,
    "abbr": "TX"
  },
  {
    "city": "Rockwall",
    "state": "Texas",
    "lat": 32.9169,
    "lng": -96.4379,
    "abbr": "TX"
  },
  {
    "city": "Grapevine",
    "state": "Texas",
    "lat": 32.9343,
    "lng": -97.0744,
    "abbr": "TX"
  },
  {
    "city": "Addison",
    "state": "Illinois",
    "lat": 32.9587,
    "lng": -96.8356,
    "abbr": "IL"
  },
  {
    "city": "Coppell",
    "state": "Texas",
    "lat": 32.9639,
    "lng": -96.9907,
    "abbr": "TX"
  },
  {
    "city": "Poway",
    "state": "California",
    "lat": 32.9872,
    "lng": -117.0201,
    "abbr": "CA"
  },
  {
    "city": "Goose Creek",
    "state": "South Carolina",
    "lat": 32.992,
    "lng": -80.0063,
    "abbr": "SC"
  },
  {
    "city": "Pittsburg",
    "state": "California",
    "lat": 32.9982,
    "lng": -94.9671,
    "abbr": "CA"
  },
  {
    "city": "Stamford",
    "state": "Connecticut",
    "lat": 33.0156,
    "lng": -99.6716,
    "abbr": "CT"
  },
  {
    "city": "Flower Mound",
    "state": "Texas",
    "lat": 33.0344,
    "lng": -97.1147,
    "abbr": "TX"
  },
  {
    "city": "Wylie",
    "state": "Texas",
    "lat": 33.0353,
    "lng": -96.517,
    "abbr": "TX"
  },
  {
    "city": "Encinitas",
    "state": "California",
    "lat": 33.0492,
    "lng": -117.2613,
    "abbr": "CA"
  },
  {
    "city": "Plano",
    "state": "Texas",
    "lat": 33.0502,
    "lng": -96.7487,
    "abbr": "TX"
  },
  {
    "city": "The Colony",
    "state": "Texas",
    "lat": 33.0926,
    "lng": -96.8977,
    "abbr": "TX"
  },
  {
    "city": "Joplin",
    "state": "Missouri",
    "lat": 33.0937,
    "lng": -97.9948,
    "abbr": "MO"
  },
  {
    "city": "Allen",
    "state": "Texas",
    "lat": 33.1087,
    "lng": -96.6735,
    "abbr": "TX"
  },
  {
    "city": "Atlanta",
    "state": "Georgia",
    "lat": 33.1136,
    "lng": -94.1672,
    "abbr": "GA"
  },
  {
    "city": "Escondido",
    "state": "California",
    "lat": 33.1349,
    "lng": -117.0721,
    "abbr": "CA"
  },
  {
    "city": "Frisco",
    "state": "Texas",
    "lat": 33.1551,
    "lng": -96.8219,
    "abbr": "TX"
  },
  {
    "city": "Urbana",
    "state": "Illinois",
    "lat": 33.1596,
    "lng": -92.446,
    "abbr": "IL"
  },
  {
    "city": "Marietta",
    "state": "Georgia",
    "lat": 33.1736,
    "lng": -94.5425,
    "abbr": "GA"
  },
  {
    "city": "Omaha",
    "state": "Nebraska",
    "lat": 33.182,
    "lng": -94.7402,
    "abbr": "NE"
  },
  {
    "city": "McKinney",
    "state": "Texas",
    "lat": 33.1989,
    "lng": -96.6678,
    "abbr": "TX"
  },
  {
    "city": "Denton",
    "state": "Texas",
    "lat": 33.2165,
    "lng": -97.1411,
    "abbr": "TX"
  },
  {
    "city": "Decatur",
    "state": "Alabama",
    "lat": 33.2277,
    "lng": -97.5899,
    "abbr": "AL"
  },
  {
    "city": "Decatur",
    "state": "Illinois",
    "lat": 33.2277,
    "lng": -97.5899,
    "abbr": "IL"
  },
  {
    "city": "Tuscaloosa",
    "state": "Alabama",
    "lat": 33.2349,
    "lng": -87.5268,
    "abbr": "AL"
  },
  {
    "city": "Goodyear",
    "state": "Arizona",
    "lat": 33.2613,
    "lng": -112.3622,
    "abbr": "AZ"
  },
  {
    "city": "Pleasanton",
    "state": "California",
    "lat": 33.2759,
    "lng": -108.8792,
    "abbr": "CA"
  },
  {
    "city": "Westminster",
    "state": "California",
    "lat": 33.3649,
    "lng": -96.4598,
    "abbr": "CA"
  },
  {
    "city": "Westminster",
    "state": "Colorado",
    "lat": 33.3649,
    "lng": -96.4598,
    "abbr": "CO"
  },
  {
    "city": "Roswell",
    "state": "Georgia",
    "lat": 33.3729,
    "lng": -104.5294,
    "abbr": "GA"
  },
  {
    "city": "Roswell",
    "state": "New Mexico",
    "lat": 33.3729,
    "lng": -104.5294,
    "abbr": "NM"
  },
  {
    "city": "Tempe",
    "state": "Arizona",
    "lat": 33.3881,
    "lng": -111.9318,
    "abbr": "AZ"
  },
  {
    "city": "Apache Junction",
    "state": "Arizona",
    "lat": 33.3982,
    "lng": -111.5349,
    "abbr": "AZ"
  },
  {
    "city": "Texarkana",
    "state": "Texas",
    "lat": 33.4487,
    "lng": -94.0814,
    "abbr": "TX"
  },
  {
    "city": "San Clemente",
    "state": "California",
    "lat": 33.4498,
    "lng": -117.6103,
    "abbr": "CA"
  },
  {
    "city": "Temecula",
    "state": "California",
    "lat": 33.4928,
    "lng": -117.1315,
    "abbr": "CA"
  },
  {
    "city": "Laguna Niguel",
    "state": "California",
    "lat": 33.5275,
    "lng": -117.705,
    "abbr": "CA"
  },
  {
    "city": "Bowie",
    "state": "Maryland",
    "lat": 33.5566,
    "lng": -97.844,
    "abbr": "MD"
  },
  {
    "city": "Lubbock",
    "state": "Texas",
    "lat": 33.5665,
    "lng": -101.8867,
    "abbr": "TX"
  },
  {
    "city": "Murrieta",
    "state": "California",
    "lat": 33.572,
    "lng": -117.1909,
    "abbr": "CA"
  },
  {
    "city": "Aliso Viejo",
    "state": "California",
    "lat": 33.5792,
    "lng": -117.729,
    "abbr": "CA"
  },
  {
    "city": "Mission Viejo",
    "state": "California",
    "lat": 33.6095,
    "lng": -117.655,
    "abbr": "CA"
  },
  {
    "city": "Newport Beach",
    "state": "California",
    "lat": 33.6151,
    "lng": -117.8669,
    "abbr": "CA"
  },
  {
    "city": "Rancho Santa Margarita",
    "state": "California",
    "lat": 33.6318,
    "lng": -117.5989,
    "abbr": "CA"
  },
  {
    "city": "La Quinta",
    "state": "California",
    "lat": 33.6537,
    "lng": -116.2785,
    "abbr": "CA"
  },
  {
    "city": "Detroit",
    "state": "Michigan",
    "lat": 33.6601,
    "lng": -95.2665,
    "abbr": "MI"
  },
  {
    "city": "Lake Forest",
    "state": "California",
    "lat": 33.6606,
    "lng": -117.6712,
    "abbr": "CA"
  },
  {
    "city": "Reno",
    "state": "Nevada",
    "lat": 33.6661,
    "lng": -95.4766,
    "abbr": "NV"
  },
  {
    "city": "Costa Mesa",
    "state": "California",
    "lat": 33.6667,
    "lng": -117.9135,
    "abbr": "CA"
  },
  {
    "city": "Irvine",
    "state": "California",
    "lat": 33.6772,
    "lng": -117.7738,
    "abbr": "CA"
  },
  {
    "city": "Lake Elsinore",
    "state": "California",
    "lat": 33.6847,
    "lng": -117.3346,
    "abbr": "CA"
  },
  {
    "city": "Scottsdale",
    "state": "Arizona",
    "lat": 33.6872,
    "lng": -111.865,
    "abbr": "AZ"
  },
  {
    "city": "Menifee",
    "state": "California",
    "lat": 33.6909,
    "lng": -117.1849,
    "abbr": "CA"
  },
  {
    "city": "Coachella",
    "state": "California",
    "lat": 33.691,
    "lng": -116.1451,
    "abbr": "CA"
  },
  {
    "city": "Huntington Beach",
    "state": "California",
    "lat": 33.696,
    "lng": -118.0023,
    "abbr": "CA"
  },
  {
    "city": "Fountain Valley",
    "state": "California",
    "lat": 33.7105,
    "lng": -117.9514,
    "abbr": "CA"
  },
  {
    "city": "Indio",
    "state": "California",
    "lat": 33.7346,
    "lng": -116.2347,
    "abbr": "CA"
  },
  {
    "city": "Hemet",
    "state": "California",
    "lat": 33.7352,
    "lng": -116.9946,
    "abbr": "CA"
  },
  {
    "city": "Santa Ana",
    "state": "California",
    "lat": 33.7366,
    "lng": -117.8819,
    "abbr": "CA"
  },
  {
    "city": "Palm Desert",
    "state": "California",
    "lat": 33.7378,
    "lng": -116.3696,
    "abbr": "CA"
  },
  {
    "city": "Rancho Palos Verdes",
    "state": "California",
    "lat": 33.7554,
    "lng": -118.3636,
    "abbr": "CA"
  },
  {
    "city": "Perris",
    "state": "California",
    "lat": 33.7897,
    "lng": -117.2233,
    "abbr": "CA"
  },
  {
    "city": "San Jacinto",
    "state": "California",
    "lat": 33.797,
    "lng": -116.9915,
    "abbr": "CA"
  },
  {
    "city": "Torrance",
    "state": "California",
    "lat": 33.8346,
    "lng": -118.3417,
    "abbr": "CA"
  },
  {
    "city": "Cathedral City",
    "state": "California",
    "lat": 33.8363,
    "lng": -116.4662,
    "abbr": "CA"
  },
  {
    "city": "Anaheim",
    "state": "California",
    "lat": 33.839,
    "lng": -117.8572,
    "abbr": "CA"
  },
  {
    "city": "Buena Park",
    "state": "California",
    "lat": 33.8572,
    "lng": -118.0046,
    "abbr": "CA"
  },
  {
    "city": "Redondo Beach",
    "state": "California",
    "lat": 33.8574,
    "lng": -118.3766,
    "abbr": "CA"
  },
  {
    "city": "Cerritos",
    "state": "California",
    "lat": 33.8677,
    "lng": -118.0686,
    "abbr": "CA"
  },
  {
    "city": "Placentia",
    "state": "California",
    "lat": 33.8807,
    "lng": -117.8553,
    "abbr": "CA"
  },
  {
    "city": "Bellflower",
    "state": "California",
    "lat": 33.888,
    "lng": -118.1271,
    "abbr": "CA"
  },
  {
    "city": "Yorba Linda",
    "state": "California",
    "lat": 33.889,
    "lng": -117.7714,
    "abbr": "CA"
  },
  {
    "city": "Compton",
    "state": "California",
    "lat": 33.893,
    "lng": -118.2275,
    "abbr": "CA"
  },
  {
    "city": "Paramount",
    "state": "California",
    "lat": 33.8976,
    "lng": -118.1651,
    "abbr": "CA"
  },
  {
    "city": "La Mirada",
    "state": "California",
    "lat": 33.9025,
    "lng": -118.0093,
    "abbr": "CA"
  },
  {
    "city": "Wichita Falls",
    "state": "Texas",
    "lat": 33.9072,
    "lng": -98.5293,
    "abbr": "TX"
  },
  {
    "city": "Lynwood",
    "state": "California",
    "lat": 33.924,
    "lng": -118.2017,
    "abbr": "CA"
  },
  {
    "city": "Moreno Valley",
    "state": "California",
    "lat": 33.9244,
    "lng": -117.2045,
    "abbr": "CA"
  },
  {
    "city": "Brea",
    "state": "California",
    "lat": 33.9255,
    "lng": -117.8654,
    "abbr": "CA"
  },
  {
    "city": "La Habra",
    "state": "California",
    "lat": 33.9278,
    "lng": -117.9513,
    "abbr": "CA"
  },
  {
    "city": "Beaverton",
    "state": "Oregon",
    "lat": 33.9354,
    "lng": -88.0218,
    "abbr": "OR"
  },
  {
    "city": "Sandy Springs",
    "state": "Georgia",
    "lat": 33.9366,
    "lng": -84.3703,
    "abbr": "GA"
  },
  {
    "city": "Downey",
    "state": "California",
    "lat": 33.9379,
    "lng": -118.1312,
    "abbr": "CA"
  },
  {
    "city": "Dunwoody",
    "state": "Georgia",
    "lat": 33.9418,
    "lng": -84.3123,
    "abbr": "GA"
  },
  {
    "city": "South Gate",
    "state": "California",
    "lat": 33.9448,
    "lng": -118.1926,
    "abbr": "CA"
  },
  {
    "city": "Chino Hills",
    "state": "California",
    "lat": 33.9508,
    "lng": -117.7254,
    "abbr": "CA"
  },
  {
    "city": "Bell Gardens",
    "state": "California",
    "lat": 33.9663,
    "lng": -118.155,
    "abbr": "CA"
  },
  {
    "city": "Peachtree Corners",
    "state": "Georgia",
    "lat": 33.9668,
    "lng": -84.2321,
    "abbr": "GA"
  },
  {
    "city": "Huntington Park",
    "state": "California",
    "lat": 33.98,
    "lng": -118.2167,
    "abbr": "CA"
  },
  {
    "city": "Chino",
    "state": "California",
    "lat": 33.9836,
    "lng": -117.6653,
    "abbr": "CA"
  },
  {
    "city": "Pico Rivera",
    "state": "California",
    "lat": 33.9902,
    "lng": -118.0888,
    "abbr": "CA"
  },
  {
    "city": "Diamond Bar",
    "state": "California",
    "lat": 33.9992,
    "lng": -117.8161,
    "abbr": "CA"
  },
  {
    "city": "Duluth",
    "state": "Minnesota",
    "lat": 34.0054,
    "lng": -84.1493,
    "abbr": "MN"
  },
  {
    "city": "Culver City",
    "state": "California",
    "lat": 34.0058,
    "lng": -118.3968,
    "abbr": "CA"
  },
  {
    "city": "Johns Creek",
    "state": "Georgia",
    "lat": 34.0333,
    "lng": -84.2027,
    "abbr": "GA"
  },
  {
    "city": "Yucaipa",
    "state": "California",
    "lat": 34.0335,
    "lng": -117.0426,
    "abbr": "CA"
  },
  {
    "city": "Provo",
    "state": "Utah",
    "lat": 34.0376,
    "lng": -94.1077,
    "abbr": "UT"
  },
  {
    "city": "Redlands",
    "state": "California",
    "lat": 34.0512,
    "lng": -117.171,
    "abbr": "CA"
  },
  {
    "city": "West Covina",
    "state": "California",
    "lat": 34.0555,
    "lng": -117.9112,
    "abbr": "CA"
  },
  {
    "city": "Rosemead",
    "state": "California",
    "lat": 34.0688,
    "lng": -118.0823,
    "abbr": "CA"
  },
  {
    "city": "Alpharetta",
    "state": "Georgia",
    "lat": 34.0704,
    "lng": -84.2739,
    "abbr": "GA"
  },
  {
    "city": "El Monte",
    "state": "California",
    "lat": 34.0739,
    "lng": -118.0291,
    "abbr": "CA"
  },
  {
    "city": "Baldwin Park",
    "state": "California",
    "lat": 34.0829,
    "lng": -117.972,
    "abbr": "CA"
  },
  {
    "city": "Alhambra",
    "state": "California",
    "lat": 34.084,
    "lng": -118.1355,
    "abbr": "CA"
  },
  {
    "city": "Covina",
    "state": "California",
    "lat": 34.0903,
    "lng": -117.8817,
    "abbr": "CA"
  },
  {
    "city": "San Gabriel",
    "state": "California",
    "lat": 34.0948,
    "lng": -118.099,
    "abbr": "CA"
  },
  {
    "city": "Rialto",
    "state": "California",
    "lat": 34.1128,
    "lng": -117.3885,
    "abbr": "CA"
  },
  {
    "city": "Rancho Cucamonga",
    "state": "California",
    "lat": 34.1246,
    "lng": -117.5662,
    "abbr": "CA"
  },
  {
    "city": "Azusa",
    "state": "California",
    "lat": 34.1386,
    "lng": -117.9124,
    "abbr": "CA"
  },
  {
    "city": "San Bernardino",
    "state": "California",
    "lat": 34.1412,
    "lng": -117.2936,
    "abbr": "CA"
  },
  {
    "city": "Monrovia",
    "state": "California",
    "lat": 34.165,
    "lng": -117.9921,
    "abbr": "CA"
  },
  {
    "city": "Oak Park",
    "state": "Illinois",
    "lat": 34.185,
    "lng": -118.7669,
    "abbr": "IL"
  },
  {
    "city": "Thousand Oaks",
    "state": "California",
    "lat": 34.1914,
    "lng": -118.8755,
    "abbr": "CA"
  },
  {
    "city": "Oxnard",
    "state": "California",
    "lat": 34.1961,
    "lng": -119.1821,
    "abbr": "CA"
  },
  {
    "city": "Pine Bluff",
    "state": "Arkansas",
    "lat": 34.2119,
    "lng": -92.0174,
    "abbr": "AR"
  },
  {
    "city": "Camarillo",
    "state": "California",
    "lat": 34.223,
    "lng": -119.0325,
    "abbr": "CA"
  },
  {
    "city": "Corona",
    "state": "California",
    "lat": 34.25,
    "lng": -105.5971,
    "abbr": "CA"
  },
  {
    "city": "Chicopee",
    "state": "Massachusetts",
    "lat": 34.2534,
    "lng": -83.8435,
    "abbr": "MA"
  },
  {
    "city": "Ogden",
    "state": "Utah",
    "lat": 34.2656,
    "lng": -77.7966,
    "abbr": "UT"
  },
  {
    "city": "Simi Valley",
    "state": "California",
    "lat": 34.2663,
    "lng": -118.749,
    "abbr": "CA"
  },
  {
    "city": "Hesperia",
    "state": "California",
    "lat": 34.3974,
    "lng": -117.3144,
    "abbr": "CA"
  },
  {
    "city": "Santa Clarita",
    "state": "California",
    "lat": 34.4119,
    "lng": -118.5036,
    "abbr": "CA"
  },
  {
    "city": "Santa Barbara",
    "state": "California",
    "lat": 34.4285,
    "lng": -119.7202,
    "abbr": "CA"
  },
  {
    "city": "Clovis",
    "state": "California",
    "lat": 34.4375,
    "lng": -103.1924,
    "abbr": "CA"
  },
  {
    "city": "Clovis",
    "state": "New Mexico",
    "lat": 34.4375,
    "lng": -103.1924,
    "abbr": "NM"
  },
  {
    "city": "Lake Havasu City",
    "state": "Arizona",
    "lat": 34.5006,
    "lng": -114.3115,
    "abbr": "AZ"
  },
  {
    "city": "Victorville",
    "state": "California",
    "lat": 34.5277,
    "lng": -117.3537,
    "abbr": "CA"
  },
  {
    "city": "Apple Valley",
    "state": "California",
    "lat": 34.5329,
    "lng": -117.2102,
    "abbr": "CA"
  },
  {
    "city": "Apple Valley",
    "state": "Minnesota",
    "lat": 34.5329,
    "lng": -117.2102,
    "abbr": "MN"
  },
  {
    "city": "Prescott Valley",
    "state": "Arizona",
    "lat": 34.5983,
    "lng": -112.3178,
    "abbr": "AZ"
  },
  {
    "city": "Lompoc",
    "state": "California",
    "lat": 34.6617,
    "lng": -120.4714,
    "abbr": "CA"
  },
  {
    "city": "Little Rock",
    "state": "Arkansas",
    "lat": 34.7255,
    "lng": -92.3584,
    "abbr": "AR"
  },
  {
    "city": "Monterey Park",
    "state": "California",
    "lat": 34.7511,
    "lng": -106.653,
    "abbr": "CA"
  },
  {
    "city": "North Little Rock",
    "state": "Arkansas",
    "lat": 34.7813,
    "lng": -92.2378,
    "abbr": "AR"
  },
  {
    "city": "Normal",
    "state": "Illinois",
    "lat": 34.789,
    "lng": -86.5719,
    "abbr": "IL"
  },
  {
    "city": "Santa Rosa",
    "state": "California",
    "lat": 34.936,
    "lng": -104.6769,
    "abbr": "CA"
  },
  {
    "city": "Spartanburg",
    "state": "South Carolina",
    "lat": 34.9437,
    "lng": -81.9256,
    "abbr": "SC"
  },
  {
    "city": "Southaven",
    "state": "Mississippi",
    "lat": 34.9514,
    "lng": -89.9787,
    "abbr": "MS"
  },
  {
    "city": "Collierville",
    "state": "Tennessee",
    "lat": 35.0474,
    "lng": -89.6989,
    "abbr": "TN"
  },
  {
    "city": "Grand Junction",
    "state": "Colorado",
    "lat": 35.0501,
    "lng": -89.1888,
    "abbr": "CO"
  },
  {
    "city": "Maricopa",
    "state": "Arizona",
    "lat": 35.0516,
    "lng": -119.4054,
    "abbr": "AZ"
  },
  {
    "city": "Chattanooga",
    "state": "Tennessee",
    "lat": 35.0657,
    "lng": -85.2488,
    "abbr": "TN"
  },
  {
    "city": "San Rafael",
    "state": "California",
    "lat": 35.0878,
    "lng": -107.8895,
    "abbr": "CA"
  },
  {
    "city": "Albuquerque",
    "state": "New Mexico",
    "lat": 35.1055,
    "lng": -106.6476,
    "abbr": "NM"
  },
  {
    "city": "Bullhead City",
    "state": "Arizona",
    "lat": 35.1205,
    "lng": -114.546,
    "abbr": "AZ"
  },
  {
    "city": "Norman",
    "state": "Oklahoma",
    "lat": 35.1698,
    "lng": -79.7232,
    "abbr": "OK"
  },
  {
    "city": "Flagstaff",
    "state": "Arizona",
    "lat": 35.1867,
    "lng": -111.6175,
    "abbr": "AZ"
  },
  {
    "city": "Alameda",
    "state": "California",
    "lat": 35.1867,
    "lng": -106.6181,
    "abbr": "CA"
  },
  {
    "city": "Amarillo",
    "state": "Texas",
    "lat": 35.1999,
    "lng": -101.8297,
    "abbr": "TX"
  },
  {
    "city": "Savannah",
    "state": "Georgia",
    "lat": 35.2211,
    "lng": -88.2356,
    "abbr": "GA"
  },
  {
    "city": "Gastonia",
    "state": "North Carolina",
    "lat": 35.2495,
    "lng": -81.1856,
    "abbr": "NC"
  },
  {
    "city": "Delano",
    "state": "California",
    "lat": 35.2651,
    "lng": -84.5533,
    "abbr": "CA"
  },
  {
    "city": "San Luis Obispo",
    "state": "California",
    "lat": 35.2671,
    "lng": -120.6689,
    "abbr": "CA"
  },
  {
    "city": "Rio Rancho",
    "state": "New Mexico",
    "lat": 35.2873,
    "lng": -106.6979,
    "abbr": "NM"
  },
  {
    "city": "Walnut Creek",
    "state": "California",
    "lat": 35.3053,
    "lng": -77.87,
    "abbr": "CA"
  },
  {
    "city": "Harrisburg",
    "state": "Pennsylvania",
    "lat": 35.3145,
    "lng": -80.6495,
    "abbr": "PA"
  },
  {
    "city": "Hendersonville",
    "state": "Tennessee",
    "lat": 35.3239,
    "lng": -82.4579,
    "abbr": "TN"
  },
  {
    "city": "San Mateo",
    "state": "California",
    "lat": 35.3321,
    "lng": -107.6469,
    "abbr": "CA"
  },
  {
    "city": "Fort Smith",
    "state": "Arkansas",
    "lat": 35.3494,
    "lng": -94.3695,
    "abbr": "AR"
  },
  {
    "city": "Greenacres",
    "state": "Florida",
    "lat": 35.3831,
    "lng": -119.1183,
    "abbr": "FL"
  },
  {
    "city": "San Jose",
    "state": "California",
    "lat": 35.3955,
    "lng": -105.4759,
    "abbr": "CA"
  },
  {
    "city": "Whittier",
    "state": "California",
    "lat": 35.4351,
    "lng": -83.3602,
    "abbr": "CA"
  },
  {
    "city": "Midwest City",
    "state": "Oklahoma",
    "lat": 35.463,
    "lng": -97.371,
    "abbr": "OK"
  },
  {
    "city": "Oklahoma City",
    "state": "Oklahoma",
    "lat": 35.4677,
    "lng": -97.5138,
    "abbr": "OK"
  },
  {
    "city": "Kannapolis",
    "state": "North Carolina",
    "lat": 35.4766,
    "lng": -80.6391,
    "abbr": "NC"
  },
  {
    "city": "Denver",
    "state": "Colorado",
    "lat": 35.535,
    "lng": -81.0342,
    "abbr": "CO"
  },
  {
    "city": "Asheville",
    "state": "North Carolina",
    "lat": 35.5703,
    "lng": -82.5536,
    "abbr": "NC"
  },
  {
    "city": "Hoover",
    "state": "Alabama",
    "lat": 35.5939,
    "lng": -100.8507,
    "abbr": "AL"
  },
  {
    "city": "Las Vegas",
    "state": "Nevada",
    "lat": 35.6011,
    "lng": -105.2206,
    "abbr": "NV"
  },
  {
    "city": "Edmond",
    "state": "Oklahoma",
    "lat": 35.6689,
    "lng": -97.4159,
    "abbr": "OK"
  },
  {
    "city": "Philadelphia",
    "state": "Pennsylvania",
    "lat": 35.6788,
    "lng": -84.3999,
    "abbr": "PA"
  },
  {
    "city": "Apex",
    "state": "North Carolina",
    "lat": 35.7248,
    "lng": -78.866,
    "abbr": "NC"
  },
  {
    "city": "Hickory",
    "state": "North Carolina",
    "lat": 35.7425,
    "lng": -81.323,
    "abbr": "NC"
  },
  {
    "city": "Muskogee",
    "state": "Oklahoma",
    "lat": 35.743,
    "lng": -95.3567,
    "abbr": "OK"
  },
  {
    "city": "Cary",
    "state": "North Carolina",
    "lat": 35.7815,
    "lng": -78.8162,
    "abbr": "NC"
  },
  {
    "city": "Mentor",
    "state": "Ohio",
    "lat": 35.8217,
    "lng": -84.0119,
    "abbr": "OH"
  },
  {
    "city": "Taylorsville",
    "state": "Utah",
    "lat": 35.9175,
    "lng": -81.1756,
    "abbr": "UT"
  },
  {
    "city": "Chapel Hill",
    "state": "North Carolina",
    "lat": 35.9271,
    "lng": -79.0387,
    "abbr": "NC"
  },
  {
    "city": "Nashville-Davidson",
    "state": "Tennessee",
    "lat": 35.9678,
    "lng": -77.9547,
    "abbr": "TN"
  },
  {
    "city": "Knoxville",
    "state": "Tennessee",
    "lat": 35.969,
    "lng": -83.9498,
    "abbr": "TN"
  },
  {
    "city": "Brentwood",
    "state": "California",
    "lat": 35.9917,
    "lng": -86.7759,
    "abbr": "CA"
  },
  {
    "city": "Brentwood",
    "state": "Tennessee",
    "lat": 35.9917,
    "lng": -86.7759,
    "abbr": "TN"
  },
  {
    "city": "Santa Cruz",
    "state": "California",
    "lat": 35.9939,
    "lng": -106.036,
    "abbr": "CA"
  },
  {
    "city": "Broken Arrow",
    "state": "Oklahoma",
    "lat": 36.0367,
    "lng": -95.7808,
    "abbr": "OK"
  },
  {
    "city": "Porterville",
    "state": "California",
    "lat": 36.0636,
    "lng": -119.0332,
    "abbr": "CA"
  },
  {
    "city": "Winston-Salem",
    "state": "North Carolina",
    "lat": 36.1029,
    "lng": -80.261,
    "abbr": "NC"
  },
  {
    "city": "Jefferson City",
    "state": "Missouri",
    "lat": 36.1196,
    "lng": -83.484,
    "abbr": "MO"
  },
  {
    "city": "Tulsa",
    "state": "Oklahoma",
    "lat": 36.1284,
    "lng": -95.9037,
    "abbr": "OK"
  },
  {
    "city": "Tulare",
    "state": "California",
    "lat": 36.1996,
    "lng": -119.34,
    "abbr": "CA"
  },
  {
    "city": "North Las Vegas",
    "state": "Nevada",
    "lat": 36.288,
    "lng": -115.0901,
    "abbr": "NV"
  },
  {
    "city": "Hanford",
    "state": "California",
    "lat": 36.326,
    "lng": -119.6539,
    "abbr": "CA"
  },
  {
    "city": "Visalia",
    "state": "California",
    "lat": 36.3271,
    "lng": -119.3265,
    "abbr": "CA"
  },
  {
    "city": "Enid",
    "state": "Oklahoma",
    "lat": 36.4061,
    "lng": -97.8701,
    "abbr": "OK"
  },
  {
    "city": "Macon",
    "state": "Georgia",
    "lat": 36.4396,
    "lng": -78.0839,
    "abbr": "GA"
  },
  {
    "city": "Murfreesboro",
    "state": "Tennessee",
    "lat": 36.4421,
    "lng": -77.0965,
    "abbr": "TN"
  },
  {
    "city": "White Plains",
    "state": "New York",
    "lat": 36.4451,
    "lng": -80.6351,
    "abbr": "NY"
  },
  {
    "city": "Lansing",
    "state": "Michigan",
    "lat": 36.4989,
    "lng": -81.5098,
    "abbr": "MI"
  },
  {
    "city": "Kingsport",
    "state": "Tennessee",
    "lat": 36.522,
    "lng": -82.5452,
    "abbr": "TN"
  },
  {
    "city": "Billings",
    "state": "Montana",
    "lat": 36.5313,
    "lng": -97.4342,
    "abbr": "MT"
  },
  {
    "city": "Eagan",
    "state": "Minnesota",
    "lat": 36.552,
    "lng": -83.9769,
    "abbr": "MN"
  },
  {
    "city": "Clarksville",
    "state": "Tennessee",
    "lat": 36.6175,
    "lng": -78.564,
    "abbr": "TN"
  },
  {
    "city": "Bristol",
    "state": "Connecticut",
    "lat": 36.618,
    "lng": -82.1607,
    "abbr": "CT"
  },
  {
    "city": "Independence",
    "state": "Missouri",
    "lat": 36.6254,
    "lng": -81.1511,
    "abbr": "MO"
  },
  {
    "city": "Chesapeake",
    "state": "Virginia",
    "lat": 36.6778,
    "lng": -76.3024,
    "abbr": "VA"
  },
  {
    "city": "Franklin",
    "state": "Tennessee",
    "lat": 36.6831,
    "lng": -76.9386,
    "abbr": "TN"
  },
  {
    "city": "Salinas",
    "state": "California",
    "lat": 36.6881,
    "lng": -121.6316,
    "abbr": "CA"
  },
  {
    "city": "Suffolk",
    "state": "Virginia",
    "lat": 36.6953,
    "lng": -76.6398,
    "abbr": "VA"
  },
  {
    "city": "La Puente",
    "state": "California",
    "lat": 36.7,
    "lng": -106.6009,
    "abbr": "CA"
  },
  {
    "city": "Boise City",
    "state": "Idaho",
    "lat": 36.7309,
    "lng": -102.5112,
    "abbr": "ID"
  },
  {
    "city": "Virginia Beach",
    "state": "Virginia",
    "lat": 36.7335,
    "lng": -76.0435,
    "abbr": "VA"
  },
  {
    "city": "Portsmouth",
    "state": "Virginia",
    "lat": 36.8468,
    "lng": -76.354,
    "abbr": "VA"
  },
  {
    "city": "Norfolk",
    "state": "Virginia",
    "lat": 36.8945,
    "lng": -76.259,
    "abbr": "VA"
  },
  {
    "city": "St. Paul",
    "state": "Minnesota",
    "lat": 36.9067,
    "lng": -82.3158,
    "abbr": "MN"
  },
  {
    "city": "St. Cloud",
    "state": "Minnesota",
    "lat": 36.9067,
    "lng": -82.3158,
    "abbr": "MN"
  },
  {
    "city": "St. Louis Park",
    "state": "Minnesota",
    "lat": 36.9067,
    "lng": -82.3158,
    "abbr": "MN"
  },
  {
    "city": "Watsonville",
    "state": "California",
    "lat": 36.9206,
    "lng": -121.7706,
    "abbr": "CA"
  },
  {
    "city": "Cedar Hill",
    "state": "Texas",
    "lat": 36.9296,
    "lng": -107.8871,
    "abbr": "TX"
  },
  {
    "city": "Carrollton",
    "state": "Texas",
    "lat": 36.9394,
    "lng": -76.5264,
    "abbr": "TX"
  },
  {
    "city": "Cleveland",
    "state": "Ohio",
    "lat": 36.9434,
    "lng": -82.1527,
    "abbr": "OH"
  },
  {
    "city": "Cleveland",
    "state": "Tennessee",
    "lat": 36.9434,
    "lng": -82.1527,
    "abbr": "TN"
  },
  {
    "city": "Victoria",
    "state": "Texas",
    "lat": 36.9947,
    "lng": -78.2242,
    "abbr": "TX"
  },
  {
    "city": "Draper",
    "state": "Utah",
    "lat": 37.0012,
    "lng": -80.7373,
    "abbr": "UT"
  },
  {
    "city": "Rocky Mount",
    "state": "North Carolina",
    "lat": 37.0045,
    "lng": -79.8855,
    "abbr": "NC"
  },
  {
    "city": "Gilroy",
    "state": "California",
    "lat": 37.0048,
    "lng": -121.5843,
    "abbr": "CA"
  },
  {
    "city": "Hampton",
    "state": "Virginia",
    "lat": 37.0551,
    "lng": -76.3629,
    "abbr": "VA"
  },
  {
    "city": "Dublin",
    "state": "California",
    "lat": 37.0989,
    "lng": -80.6831,
    "abbr": "CA"
  },
  {
    "city": "Dublin",
    "state": "Ohio",
    "lat": 37.0989,
    "lng": -80.6831,
    "abbr": "OH"
  },
  {
    "city": "Newport News",
    "state": "Virginia",
    "lat": 37.1052,
    "lng": -76.5185,
    "abbr": "VA"
  },
  {
    "city": "Morgan Hill",
    "state": "California",
    "lat": 37.1324,
    "lng": -121.641,
    "abbr": "CA"
  },
  {
    "city": "St. Petersburg",
    "state": "Florida",
    "lat": 37.2042,
    "lng": -77.3914,
    "abbr": "FL"
  },
  {
    "city": "Port St. Lucie",
    "state": "Florida",
    "lat": 37.2042,
    "lng": -77.3914,
    "abbr": "FL"
  },
  {
    "city": "St. Cloud",
    "state": "Florida",
    "lat": 37.2042,
    "lng": -77.3914,
    "abbr": "FL"
  },
  {
    "city": "Blacksburg",
    "state": "Virginia",
    "lat": 37.23,
    "lng": -80.4277,
    "abbr": "VA"
  },
  {
    "city": "Lafayette",
    "state": "Indiana",
    "lat": 37.233,
    "lng": -80.2051,
    "abbr": "IN"
  },
  {
    "city": "Lafayette",
    "state": "Louisiana",
    "lat": 37.233,
    "lng": -80.2051,
    "abbr": "LA"
  },
  {
    "city": "Roanoke",
    "state": "Virginia",
    "lat": 37.2784,
    "lng": -79.9581,
    "abbr": "VA"
  },
  {
    "city": "Salem",
    "state": "Massachusetts",
    "lat": 37.2864,
    "lng": -80.0554,
    "abbr": "MA"
  },
  {
    "city": "Salem",
    "state": "Oregon",
    "lat": 37.2864,
    "lng": -80.0554,
    "abbr": "OR"
  },
  {
    "city": "Merced",
    "state": "California",
    "lat": 37.3057,
    "lng": -120.4779,
    "abbr": "CA"
  },
  {
    "city": "Cape Girardeau",
    "state": "Missouri",
    "lat": 37.3107,
    "lng": -89.5595,
    "abbr": "MO"
  },
  {
    "city": "Cupertino",
    "state": "California",
    "lat": 37.3167,
    "lng": -122.0465,
    "abbr": "CA"
  },
  {
    "city": "Bedford",
    "state": "Texas",
    "lat": 37.3359,
    "lng": -79.5181,
    "abbr": "TX"
  },
  {
    "city": "Concord",
    "state": "California",
    "lat": 37.3426,
    "lng": -78.9862,
    "abbr": "CA"
  },
  {
    "city": "Concord",
    "state": "New Hampshire",
    "lat": 37.3426,
    "lng": -78.9862,
    "abbr": "NH"
  },
  {
    "city": "Concord",
    "state": "North Carolina",
    "lat": 37.3426,
    "lng": -78.9862,
    "abbr": "NC"
  },
  {
    "city": "Gary",
    "state": "Indiana",
    "lat": 37.3621,
    "lng": -81.5405,
    "abbr": "IN"
  },
  {
    "city": "Chesterfield",
    "state": "Missouri",
    "lat": 37.3771,
    "lng": -77.5058,
    "abbr": "MO"
  },
  {
    "city": "Lynchburg",
    "state": "Virginia",
    "lat": 37.4004,
    "lng": -79.1911,
    "abbr": "VA"
  },
  {
    "city": "Lynn",
    "state": "Massachusetts",
    "lat": 37.4219,
    "lng": -104.643,
    "abbr": "MA"
  },
  {
    "city": "Milpitas",
    "state": "California",
    "lat": 37.4338,
    "lng": -121.8921,
    "abbr": "CA"
  },
  {
    "city": "Redwood City",
    "state": "California",
    "lat": 37.5026,
    "lng": -122.2251,
    "abbr": "CA"
  },
  {
    "city": "Turlock",
    "state": "California",
    "lat": 37.5053,
    "lng": -120.8588,
    "abbr": "CA"
  },
  {
    "city": "Richmond",
    "state": "California",
    "lat": 37.5294,
    "lng": -77.4755,
    "abbr": "CA"
  },
  {
    "city": "Richmond",
    "state": "Virginia",
    "lat": 37.5294,
    "lng": -77.4755,
    "abbr": "VA"
  },
  {
    "city": "Ceres",
    "state": "California",
    "lat": 37.5954,
    "lng": -120.9624,
    "abbr": "CA"
  },
  {
    "city": "Boston",
    "state": "Massachusetts",
    "lat": 37.6039,
    "lng": -75.8437,
    "abbr": "MA"
  },
  {
    "city": "Pacifica",
    "state": "California",
    "lat": 37.6112,
    "lng": -122.4781,
    "abbr": "CA"
  },
  {
    "city": "Gilbert",
    "state": "Arizona",
    "lat": 37.6142,
    "lng": -81.8689,
    "abbr": "AZ"
  },
  {
    "city": "San Bruno",
    "state": "California",
    "lat": 37.6254,
    "lng": -122.4313,
    "abbr": "CA"
  },
  {
    "city": "Modesto",
    "state": "California",
    "lat": 37.6371,
    "lng": -121.003,
    "abbr": "CA"
  },
  {
    "city": "South San Francisco",
    "state": "California",
    "lat": 37.6537,
    "lng": -122.4197,
    "abbr": "CA"
  },
  {
    "city": "Daly City",
    "state": "California",
    "lat": 37.6863,
    "lng": -122.4684,
    "abbr": "CA"
  },
  {
    "city": "Livermore",
    "state": "California",
    "lat": 37.6863,
    "lng": -121.7607,
    "abbr": "CA"
  },
  {
    "city": "Wichita",
    "state": "Kansas",
    "lat": 37.6894,
    "lng": -97.344,
    "abbr": "KS"
  },
  {
    "city": "San Leandro",
    "state": "California",
    "lat": 37.7071,
    "lng": -122.1601,
    "abbr": "CA"
  },
  {
    "city": "Tracy",
    "state": "California",
    "lat": 37.7254,
    "lng": -121.4447,
    "abbr": "CA"
  },
  {
    "city": "Columbia",
    "state": "Missouri",
    "lat": 37.7533,
    "lng": -78.1638,
    "abbr": "MO"
  },
  {
    "city": "Columbia",
    "state": "South Carolina",
    "lat": 37.7533,
    "lng": -78.1638,
    "abbr": "SC"
  },
  {
    "city": "San Francisco",
    "state": "California",
    "lat": 37.7561,
    "lng": -122.4429,
    "abbr": "CA"
  },
  {
    "city": "Owensboro",
    "state": "Kentucky",
    "lat": 37.7574,
    "lng": -87.1181,
    "abbr": "KY"
  },
  {
    "city": "San Ramon",
    "state": "California",
    "lat": 37.7625,
    "lng": -121.9366,
    "abbr": "CA"
  },
  {
    "city": "Lancaster",
    "state": "California",
    "lat": 37.7675,
    "lng": -76.4549,
    "abbr": "CA"
  },
  {
    "city": "Lancaster",
    "state": "Ohio",
    "lat": 37.7675,
    "lng": -76.4549,
    "abbr": "OH"
  },
  {
    "city": "Lancaster",
    "state": "Pennsylvania",
    "lat": 37.7675,
    "lng": -76.4549,
    "abbr": "PA"
  },
  {
    "city": "Lancaster",
    "state": "Texas",
    "lat": 37.7675,
    "lng": -76.4549,
    "abbr": "TX"
  },
  {
    "city": "Caldwell",
    "state": "Idaho",
    "lat": 37.7807,
    "lng": -80.394,
    "abbr": "ID"
  },
  {
    "city": "Manteca",
    "state": "California",
    "lat": 37.7938,
    "lng": -121.2271,
    "abbr": "CA"
  },
  {
    "city": "Hutchinson",
    "state": "Kansas",
    "lat": 37.8168,
    "lng": -81.9082,
    "abbr": "KS"
  },
  {
    "city": "Logan",
    "state": "Utah",
    "lat": 37.8509,
    "lng": -81.9857,
    "abbr": "UT"
  },
  {
    "city": "Augusta-Richmond County",
    "state": "Georgia",
    "lat": 37.8854,
    "lng": -76.625,
    "abbr": "GA"
  },
  {
    "city": "Sanford",
    "state": "Florida",
    "lat": 37.9299,
    "lng": -75.662,
    "abbr": "FL"
  },
  {
    "city": "Antioch",
    "state": "California",
    "lat": 37.9789,
    "lng": -121.7958,
    "abbr": "CA"
  },
  {
    "city": "Oakley",
    "state": "California",
    "lat": 37.9929,
    "lng": -121.6951,
    "abbr": "CA"
  },
  {
    "city": "Martinez",
    "state": "California",
    "lat": 37.9985,
    "lng": -122.116,
    "abbr": "CA"
  },
  {
    "city": "Greenville",
    "state": "North Carolina",
    "lat": 38.0036,
    "lng": -79.1522,
    "abbr": "NC"
  },
  {
    "city": "Greenville",
    "state": "South Carolina",
    "lat": 38.0036,
    "lng": -79.1522,
    "abbr": "SC"
  },
  {
    "city": "Milford",
    "state": "Connecticut",
    "lat": 38.0232,
    "lng": -77.3733,
    "abbr": "CT"
  },
  {
    "city": "Charlottesville",
    "state": "Virginia",
    "lat": 38.0374,
    "lng": -78.4856,
    "abbr": "VA"
  },
  {
    "city": "Bowling Green",
    "state": "Kentucky",
    "lat": 38.0533,
    "lng": -77.3473,
    "abbr": "KY"
  },
  {
    "city": "Fayetteville",
    "state": "Arkansas",
    "lat": 38.0623,
    "lng": -81.1084,
    "abbr": "AR"
  },
  {
    "city": "Fayetteville",
    "state": "North Carolina",
    "lat": 38.0623,
    "lng": -81.1084,
    "abbr": "NC"
  },
  {
    "city": "Novato",
    "state": "California",
    "lat": 38.092,
    "lng": -122.5576,
    "abbr": "CA"
  },
  {
    "city": "Vallejo",
    "state": "California",
    "lat": 38.1134,
    "lng": -122.2359,
    "abbr": "CA"
  },
  {
    "city": "Montgomery",
    "state": "Alabama",
    "lat": 38.1741,
    "lng": -81.3241,
    "abbr": "AL"
  },
  {
    "city": "Huntersville",
    "state": "North Carolina",
    "lat": 38.1859,
    "lng": -80.0151,
    "abbr": "NC"
  },
  {
    "city": "Petaluma",
    "state": "California",
    "lat": 38.2423,
    "lng": -122.6267,
    "abbr": "CA"
  },
  {
    "city": "Orange",
    "state": "California",
    "lat": 38.2484,
    "lng": -78.1125,
    "abbr": "CA"
  },
  {
    "city": "Pueblo",
    "state": "Colorado",
    "lat": 38.2701,
    "lng": -104.613,
    "abbr": "CO"
  },
  {
    "city": "Napa",
    "state": "California",
    "lat": 38.2982,
    "lng": -122.3011,
    "abbr": "CA"
  },
  {
    "city": "Rohnert Park",
    "state": "California",
    "lat": 38.348,
    "lng": -122.6964,
    "abbr": "CA"
  },
  {
    "city": "Charleston",
    "state": "South Carolina",
    "lat": 38.3484,
    "lng": -81.6323,
    "abbr": "SC"
  },
  {
    "city": "Charleston",
    "state": "West Virginia",
    "lat": 38.3484,
    "lng": -81.6323,
    "abbr": "WV"
  },
  {
    "city": "Eugene",
    "state": "Oregon",
    "lat": 38.3528,
    "lng": -92.4038,
    "abbr": "OR"
  },
  {
    "city": "Vacaville",
    "state": "California",
    "lat": 38.3592,
    "lng": -121.9686,
    "abbr": "CA"
  },
  {
    "city": "Madison",
    "state": "Alabama",
    "lat": 38.379,
    "lng": -78.2586,
    "abbr": "AL"
  },
  {
    "city": "Madison",
    "state": "Wisconsin",
    "lat": 38.379,
    "lng": -78.2586,
    "abbr": "WI"
  },
  {
    "city": "Elk Grove",
    "state": "California",
    "lat": 38.416,
    "lng": -121.3841,
    "abbr": "CA"
  },
  {
    "city": "Harrisonburg",
    "state": "Virginia",
    "lat": 38.4362,
    "lng": -78.8735,
    "abbr": "VA"
  },
  {
    "city": "West Sacramento",
    "state": "California",
    "lat": 38.5556,
    "lng": -121.5504,
    "abbr": "CA"
  },
  {
    "city": "Rancho Cordova",
    "state": "California",
    "lat": 38.5739,
    "lng": -121.2521,
    "abbr": "CA"
  },
  {
    "city": "O'Fallon",
    "state": "Missouri",
    "lat": 38.597,
    "lng": -89.9154,
    "abbr": "MO"
  },
  {
    "city": "Olathe",
    "state": "Kansas",
    "lat": 38.6084,
    "lng": -107.983,
    "abbr": "KS"
  },
  {
    "city": "Montclair",
    "state": "California",
    "lat": 38.6111,
    "lng": -77.34,
    "abbr": "CA"
  },
  {
    "city": "Buffalo",
    "state": "New York",
    "lat": 38.6117,
    "lng": -81.982,
    "abbr": "NY"
  },
  {
    "city": "Citrus Heights",
    "state": "California",
    "lat": 38.6948,
    "lng": -121.288,
    "abbr": "CA"
  },
  {
    "city": "Washington",
    "state": "District of Columbia",
    "lat": 38.7121,
    "lng": -78.1599,
    "abbr": "DC"
  },
  {
    "city": "Royal Oak",
    "state": "Michigan",
    "lat": 38.7423,
    "lng": -76.1777,
    "abbr": "MI"
  },
  {
    "city": "Wellington",
    "state": "Florida",
    "lat": 38.7457,
    "lng": -77.0553,
    "abbr": "FL"
  },
  {
    "city": "Manassas",
    "state": "Virginia",
    "lat": 38.748,
    "lng": -77.484,
    "abbr": "VA"
  },
  {
    "city": "Clifton",
    "state": "New Jersey",
    "lat": 38.7802,
    "lng": -77.3866,
    "abbr": "NJ"
  },
  {
    "city": "Springfield",
    "state": "Illinois",
    "lat": 38.7809,
    "lng": -77.1839,
    "abbr": "IL"
  },
  {
    "city": "Springfield",
    "state": "Massachusetts",
    "lat": 38.7809,
    "lng": -77.1839,
    "abbr": "MA"
  },
  {
    "city": "Springfield",
    "state": "Missouri",
    "lat": 38.7809,
    "lng": -77.1839,
    "abbr": "MO"
  },
  {
    "city": "Springfield",
    "state": "Ohio",
    "lat": 38.7809,
    "lng": -77.1839,
    "abbr": "OH"
  },
  {
    "city": "Springfield",
    "state": "Oregon",
    "lat": 38.7809,
    "lng": -77.1839,
    "abbr": "OR"
  },
  {
    "city": "Huntington",
    "state": "West Virginia",
    "lat": 38.7914,
    "lng": -77.0741,
    "abbr": "WV"
  },
  {
    "city": "Gainesville",
    "state": "Florida",
    "lat": 38.7931,
    "lng": -77.6347,
    "abbr": "FL"
  },
  {
    "city": "Greenwood",
    "state": "Indiana",
    "lat": 38.8069,
    "lng": -75.5907,
    "abbr": "IN"
  },
  {
    "city": "Rocklin",
    "state": "California",
    "lat": 38.8078,
    "lng": -121.2491,
    "abbr": "CA"
  },
  {
    "city": "Alexandria",
    "state": "Louisiana",
    "lat": 38.8184,
    "lng": -77.0861,
    "abbr": "LA"
  },
  {
    "city": "Alexandria",
    "state": "Virginia",
    "lat": 38.8184,
    "lng": -77.0861,
    "abbr": "VA"
  },
  {
    "city": "Edinburg",
    "state": "Texas",
    "lat": 38.8236,
    "lng": -78.5633,
    "abbr": "TX"
  },
  {
    "city": "Henderson",
    "state": "Nevada",
    "lat": 38.8304,
    "lng": -82.136,
    "abbr": "NV"
  },
  {
    "city": "Bentonville",
    "state": "Arkansas",
    "lat": 38.8318,
    "lng": -78.3164,
    "abbr": "AR"
  },
  {
    "city": "Burnsville",
    "state": "Minnesota",
    "lat": 38.8579,
    "lng": -80.6538,
    "abbr": "MN"
  },
  {
    "city": "Colorado Springs",
    "state": "Colorado",
    "lat": 38.8673,
    "lng": -104.7605,
    "abbr": "CO"
  },
  {
    "city": "Orlando",
    "state": "Florida",
    "lat": 38.8712,
    "lng": -80.5937,
    "abbr": "FL"
  },
  {
    "city": "Overland Park",
    "state": "Kansas",
    "lat": 38.8871,
    "lng": -94.6871,
    "abbr": "KS"
  },
  {
    "city": "Kettering",
    "state": "Ohio",
    "lat": 38.8888,
    "lng": -76.7889,
    "abbr": "OH"
  },
  {
    "city": "Houston",
    "state": "Texas",
    "lat": 38.9177,
    "lng": -75.5041,
    "abbr": "TX"
  },
  {
    "city": "Florissant",
    "state": "Missouri",
    "lat": 38.9445,
    "lng": -105.2901,
    "abbr": "MO"
  },
  {
    "city": "Riverton",
    "state": "Utah",
    "lat": 38.9465,
    "lng": -78.1994,
    "abbr": "UT"
  },
  {
    "city": "Lenexa",
    "state": "Kansas",
    "lat": 38.9608,
    "lng": -94.802,
    "abbr": "KS"
  },
  {
    "city": "New Haven",
    "state": "Connecticut",
    "lat": 38.9876,
    "lng": -81.9655,
    "abbr": "CT"
  },
  {
    "city": "Great Falls",
    "state": "Montana",
    "lat": 39.011,
    "lng": -77.3013,
    "abbr": "MT"
  },
  {
    "city": "Middletown",
    "state": "Connecticut",
    "lat": 39.0288,
    "lng": -78.2781,
    "abbr": "CT"
  },
  {
    "city": "Middletown",
    "state": "Ohio",
    "lat": 39.0288,
    "lng": -78.2781,
    "abbr": "OH"
  },
  {
    "city": "Elizabeth",
    "state": "New Jersey",
    "lat": 39.0618,
    "lng": -81.3975,
    "abbr": "NJ"
  },
  {
    "city": "Leesburg",
    "state": "Virginia",
    "lat": 39.1057,
    "lng": -77.5543,
    "abbr": "VA"
  },
  {
    "city": "Wyoming",
    "state": "Michigan",
    "lat": 39.1143,
    "lng": -75.5632,
    "abbr": "MI"
  },
  {
    "city": "Kansas City",
    "state": "Kansas",
    "lat": 39.1239,
    "lng": -94.5541,
    "abbr": "KS"
  },
  {
    "city": "Kansas City",
    "state": "Missouri",
    "lat": 39.1239,
    "lng": -94.5541,
    "abbr": "MO"
  },
  {
    "city": "Davis",
    "state": "California",
    "lat": 39.1266,
    "lng": -79.4609,
    "abbr": "CA"
  },
  {
    "city": "Gaithersburg",
    "state": "Maryland",
    "lat": 39.1347,
    "lng": -77.213,
    "abbr": "MD"
  },
  {
    "city": "Yuba City",
    "state": "California",
    "lat": 39.1357,
    "lng": -121.6381,
    "abbr": "CA"
  },
  {
    "city": "Carson City",
    "state": "Nevada",
    "lat": 39.1512,
    "lng": -119.7474,
    "abbr": "NV"
  },
  {
    "city": "Dover",
    "state": "Delaware",
    "lat": 39.16,
    "lng": -75.5204,
    "abbr": "DE"
  },
  {
    "city": "Hillsboro",
    "state": "Oregon",
    "lat": 39.1997,
    "lng": -77.7244,
    "abbr": "OR"
  },
  {
    "city": "Brooklyn Park",
    "state": "Minnesota",
    "lat": 39.217,
    "lng": -76.6174,
    "abbr": "MN"
  },
  {
    "city": "Baltimore",
    "state": "Maryland",
    "lat": 39.3051,
    "lng": -76.6144,
    "abbr": "MD"
  },
  {
    "city": "Aurora",
    "state": "Colorado",
    "lat": 39.3254,
    "lng": -79.5546,
    "abbr": "CO"
  },
  {
    "city": "Aurora",
    "state": "Illinois",
    "lat": 39.3254,
    "lng": -79.5546,
    "abbr": "IL"
  },
  {
    "city": "Vineland",
    "state": "New Jersey",
    "lat": 39.4652,
    "lng": -74.9981,
    "abbr": "NJ"
  },
  {
    "city": "Terre Haute",
    "state": "Indiana",
    "lat": 39.4654,
    "lng": -87.3763,
    "abbr": "IN"
  },
  {
    "city": "Pomona",
    "state": "California",
    "lat": 39.4687,
    "lng": -74.5501,
    "abbr": "CA"
  },
  {
    "city": "Folsom",
    "state": "California",
    "lat": 39.4706,
    "lng": -80.5204,
    "abbr": "CA"
  },
  {
    "city": "Findlay",
    "state": "Ohio",
    "lat": 39.5223,
    "lng": -88.7546,
    "abbr": "OH"
  },
  {
    "city": "Dearborn",
    "state": "Michigan",
    "lat": 39.5247,
    "lng": -94.7741,
    "abbr": "MI"
  },
  {
    "city": "Brookhaven",
    "state": "Georgia",
    "lat": 39.6062,
    "lng": -79.8812,
    "abbr": "GA"
  },
  {
    "city": "Peoria",
    "state": "Arizona",
    "lat": 39.6649,
    "lng": -104.1456,
    "abbr": "AZ"
  },
  {
    "city": "Peoria",
    "state": "Illinois",
    "lat": 39.6649,
    "lng": -104.1456,
    "abbr": "IL"
  },
  {
    "city": "Newark",
    "state": "California",
    "lat": 39.6777,
    "lng": -75.7573,
    "abbr": "CA"
  },
  {
    "city": "Newark",
    "state": "New Jersey",
    "lat": 39.6777,
    "lng": -75.7573,
    "abbr": "NJ"
  },
  {
    "city": "Newark",
    "state": "Ohio",
    "lat": 39.6777,
    "lng": -75.7573,
    "abbr": "OH"
  },
  {
    "city": "Littleton",
    "state": "Colorado",
    "lat": 39.7024,
    "lng": -80.5147,
    "abbr": "CO"
  },
  {
    "city": "Stanton",
    "state": "California",
    "lat": 39.7157,
    "lng": -75.6408,
    "abbr": "CA"
  },
  {
    "city": "Beavercreek",
    "state": "Ohio",
    "lat": 39.7312,
    "lng": -84.0627,
    "abbr": "OH"
  },
  {
    "city": "Wilmington",
    "state": "Delaware",
    "lat": 39.7349,
    "lng": -75.5286,
    "abbr": "DE"
  },
  {
    "city": "Wilmington",
    "state": "North Carolina",
    "lat": 39.7349,
    "lng": -75.5286,
    "abbr": "NC"
  },
  {
    "city": "Indianapolis",
    "state": "Indiana",
    "lat": 39.7771,
    "lng": -86.1458,
    "abbr": "IN"
  },
  {
    "city": "Woodbury",
    "state": "Minnesota",
    "lat": 39.8378,
    "lng": -75.1523,
    "abbr": "MN"
  },
  {
    "city": "Glendora",
    "state": "California",
    "lat": 39.8405,
    "lng": -75.0678,
    "abbr": "CA"
  },
  {
    "city": "Huber Heights",
    "state": "Ohio",
    "lat": 39.8595,
    "lng": -84.113,
    "abbr": "OH"
  },
  {
    "city": "Commerce City",
    "state": "Colorado",
    "lat": 39.8638,
    "lng": -104.8434,
    "abbr": "CO"
  },
  {
    "city": "Northglenn",
    "state": "Colorado",
    "lat": 39.9107,
    "lng": -104.9783,
    "abbr": "CO"
  },
  {
    "city": "Hagerstown",
    "state": "Maryland",
    "lat": 39.9115,
    "lng": -85.1554,
    "abbr": "MD"
  },
  {
    "city": "Broomfield",
    "state": "Colorado",
    "lat": 39.9541,
    "lng": -105.0526,
    "abbr": "CO"
  },
  {
    "city": "Fishers",
    "state": "Indiana",
    "lat": 39.959,
    "lng": -85.968,
    "abbr": "IN"
  },
  {
    "city": "Noblesville",
    "state": "Indiana",
    "lat": 40.0354,
    "lng": -86.0056,
    "abbr": "IN"
  },
  {
    "city": "Bethlehem",
    "state": "Pennsylvania",
    "lat": 40.0452,
    "lng": -80.6898,
    "abbr": "PA"
  },
  {
    "city": "Wheeling",
    "state": "Illinois",
    "lat": 40.0753,
    "lng": -80.6951,
    "abbr": "IL"
  },
  {
    "city": "Frederick",
    "state": "Maryland",
    "lat": 40.1096,
    "lng": -104.9677,
    "abbr": "MD"
  },
  {
    "city": "Spanish Fork",
    "state": "Utah",
    "lat": 40.11,
    "lng": -111.6407,
    "abbr": "UT"
  },
  {
    "city": "Champaign",
    "state": "Illinois",
    "lat": 40.1148,
    "lng": -88.2731,
    "abbr": "IL"
  },
  {
    "city": "Muncie",
    "state": "Indiana",
    "lat": 40.116,
    "lng": -87.843,
    "abbr": "IN"
  },
  {
    "city": "Yuma",
    "state": "Arizona",
    "lat": 40.1239,
    "lng": -102.7164,
    "abbr": "AZ"
  },
  {
    "city": "Blue Springs",
    "state": "Missouri",
    "lat": 40.1375,
    "lng": -96.663,
    "abbr": "MO"
  },
  {
    "city": "Edina",
    "state": "Minnesota",
    "lat": 40.168,
    "lng": -92.1732,
    "abbr": "MN"
  },
  {
    "city": "Longmont",
    "state": "Colorado",
    "lat": 40.1691,
    "lng": -105.0995,
    "abbr": "CO"
  },
  {
    "city": "Bend",
    "state": "Oregon",
    "lat": 40.2561,
    "lng": -122.2095,
    "abbr": "OR"
  },
  {
    "city": "Westland",
    "state": "Michigan",
    "lat": 40.2789,
    "lng": -80.2732,
    "abbr": "MI"
  },
  {
    "city": "Lorain",
    "state": "Ohio",
    "lat": 40.2961,
    "lng": -78.8959,
    "abbr": "OH"
  },
  {
    "city": "New Britain",
    "state": "Connecticut",
    "lat": 40.2982,
    "lng": -75.1805,
    "abbr": "CT"
  },
  {
    "city": "Orem",
    "state": "Utah",
    "lat": 40.2988,
    "lng": -111.699,
    "abbr": "UT"
  },
  {
    "city": "Upland",
    "state": "California",
    "lat": 40.3195,
    "lng": -98.9023,
    "abbr": "CA"
  },
  {
    "city": "Topeka",
    "state": "Kansas",
    "lat": 40.3304,
    "lng": -89.9306,
    "abbr": "KS"
  },
  {
    "city": "Reading",
    "state": "Pennsylvania",
    "lat": 40.34,
    "lng": -75.9266,
    "abbr": "PA"
  },
  {
    "city": "Stockton",
    "state": "California",
    "lat": 40.4062,
    "lng": -74.976,
    "abbr": "CA"
  },
  {
    "city": "Lehi",
    "state": "Utah",
    "lat": 40.414,
    "lng": -111.8729,
    "abbr": "UT"
  },
  {
    "city": "Loveland",
    "state": "Colorado",
    "lat": 40.4168,
    "lng": -105.0622,
    "abbr": "CO"
  },
  {
    "city": "Pittsburgh",
    "state": "Pennsylvania",
    "lat": 40.4396,
    "lng": -79.9763,
    "abbr": "PA"
  },
  {
    "city": "Harlingen",
    "state": "Texas",
    "lat": 40.4494,
    "lng": -74.6585,
    "abbr": "TX"
  },
  {
    "city": "Kokomo",
    "state": "Indiana",
    "lat": 40.464,
    "lng": -86.1277,
    "abbr": "IN"
  },
  {
    "city": "Sayreville",
    "state": "New Jersey",
    "lat": 40.4655,
    "lng": -74.3237,
    "abbr": "NJ"
  },
  {
    "city": "New Brunswick",
    "state": "New Jersey",
    "lat": 40.487,
    "lng": -74.445,
    "abbr": "NJ"
  },
  {
    "city": "Perth Amboy",
    "state": "New Jersey",
    "lat": 40.5204,
    "lng": -74.2724,
    "abbr": "NJ"
  },
  {
    "city": "Salina",
    "state": "Kansas",
    "lat": 40.5217,
    "lng": -79.4981,
    "abbr": "KS"
  },
  {
    "city": "Fort Collins",
    "state": "Colorado",
    "lat": 40.5487,
    "lng": -105.0656,
    "abbr": "CO"
  },
  {
    "city": "South Jordan",
    "state": "Utah",
    "lat": 40.5571,
    "lng": -111.9783,
    "abbr": "UT"
  },
  {
    "city": "Somerville",
    "state": "Massachusetts",
    "lat": 40.5696,
    "lng": -74.6092,
    "abbr": "MA"
  },
  {
    "city": "Redding",
    "state": "California",
    "lat": 40.5698,
    "lng": -122.365,
    "abbr": "CA"
  },
  {
    "city": "Holyoke",
    "state": "Massachusetts",
    "lat": 40.5826,
    "lng": -102.2985,
    "abbr": "MA"
  },
  {
    "city": "West Jordan",
    "state": "Utah",
    "lat": 40.6024,
    "lng": -112.0008,
    "abbr": "UT"
  },
  {
    "city": "Cincinnati",
    "state": "Ohio",
    "lat": 40.6309,
    "lng": -92.9222,
    "abbr": "OH"
  },
  {
    "city": "Oceanside",
    "state": "California",
    "lat": 40.6328,
    "lng": -73.6363,
    "abbr": "CA"
  },
  {
    "city": "Birmingham",
    "state": "Alabama",
    "lat": 40.6472,
    "lng": -78.1957,
    "abbr": "AL"
  },
  {
    "city": "Syracuse",
    "state": "New York",
    "lat": 40.6636,
    "lng": -96.1827,
    "abbr": "NY"
  },
  {
    "city": "Valley Stream",
    "state": "New York",
    "lat": 40.6647,
    "lng": -73.7044,
    "abbr": "NY"
  },
  {
    "city": "Bayonne",
    "state": "New Jersey",
    "lat": 40.6659,
    "lng": -74.1141,
    "abbr": "NJ"
  },
  {
    "city": "Palo Alto",
    "state": "California",
    "lat": 40.6861,
    "lng": -76.1699,
    "abbr": "CA"
  },
  {
    "city": "West Valley City",
    "state": "Utah",
    "lat": 40.6889,
    "lng": -112.0115,
    "abbr": "UT"
  },
  {
    "city": "Jersey City",
    "state": "New Jersey",
    "lat": 40.7161,
    "lng": -74.0683,
    "abbr": "NJ"
  },
  {
    "city": "Hoboken",
    "state": "New Jersey",
    "lat": 40.7452,
    "lng": -74.0279,
    "abbr": "NJ"
  },
  {
    "city": "Kearny",
    "state": "New Jersey",
    "lat": 40.7526,
    "lng": -74.1202,
    "abbr": "NJ"
  },
  {
    "city": "Anderson",
    "state": "Indiana",
    "lat": 40.7614,
    "lng": -74.9304,
    "abbr": "IN"
  },
  {
    "city": "East Orange",
    "state": "New Jersey",
    "lat": 40.7651,
    "lng": -74.2118,
    "abbr": "NJ"
  },
  {
    "city": "Eastvale",
    "state": "California",
    "lat": 40.7669,
    "lng": -80.3148,
    "abbr": "CA"
  },
  {
    "city": "Union City",
    "state": "California",
    "lat": 40.7675,
    "lng": -74.0323,
    "abbr": "CA"
  },
  {
    "city": "Union City",
    "state": "New Jersey",
    "lat": 40.7675,
    "lng": -74.0323,
    "abbr": "NJ"
  },
  {
    "city": "Salt Lake City",
    "state": "Utah",
    "lat": 40.7774,
    "lng": -111.9301,
    "abbr": "UT"
  },
  {
    "city": "Lexington-Fayette",
    "state": "Kentucky",
    "lat": 40.778,
    "lng": -99.7462,
    "abbr": "KY"
  },
  {
    "city": "Manhattan",
    "state": "Kansas",
    "lat": 40.7834,
    "lng": -73.9662,
    "abbr": "KS"
  },
  {
    "city": "West New York",
    "state": "New Jersey",
    "lat": 40.7856,
    "lng": -74.0093,
    "abbr": "NJ"
  },
  {
    "city": "State College",
    "state": "Pennsylvania",
    "lat": 40.791,
    "lng": -77.8568,
    "abbr": "PA"
  },
  {
    "city": "Buckeye",
    "state": "Arizona",
    "lat": 40.8272,
    "lng": -105.095,
    "abbr": "AZ"
  },
  {
    "city": "Madera",
    "state": "California",
    "lat": 40.8284,
    "lng": -78.435,
    "abbr": "CA"
  },
  {
    "city": "Passaic",
    "state": "New Jersey",
    "lat": 40.8574,
    "lng": -74.1281,
    "abbr": "NJ"
  },
  {
    "city": "Bountiful",
    "state": "Utah",
    "lat": 40.8722,
    "lng": -111.8647,
    "abbr": "UT"
  },
  {
    "city": "Pontiac",
    "state": "Michigan",
    "lat": 40.8881,
    "lng": -88.6415,
    "abbr": "MI"
  },
  {
    "city": "Hackensack",
    "state": "New Jersey",
    "lat": 40.8889,
    "lng": -74.0461,
    "abbr": "NJ"
  },
  {
    "city": "Murray",
    "state": "Utah",
    "lat": 40.9163,
    "lng": -95.9267,
    "abbr": "UT"
  },
  {
    "city": "Lincoln Park",
    "state": "Michigan",
    "lat": 40.9239,
    "lng": -74.3035,
    "abbr": "MI"
  },
  {
    "city": "New Rochelle",
    "state": "New York",
    "lat": 40.9305,
    "lng": -73.7836,
    "abbr": "NY"
  },
  {
    "city": "Yonkers",
    "state": "New York",
    "lat": 40.9466,
    "lng": -73.8674,
    "abbr": "NY"
  },
  {
    "city": "Roseville",
    "state": "California",
    "lat": 40.9576,
    "lng": -74.6916,
    "abbr": "CA"
  },
  {
    "city": "Roseville",
    "state": "Michigan",
    "lat": 40.9576,
    "lng": -74.6916,
    "abbr": "MI"
  },
  {
    "city": "Louisville/Jefferson County",
    "state": "Kentucky",
    "lat": 40.9973,
    "lng": -96.1606,
    "abbr": "KY"
  },
  {
    "city": "St. Louis",
    "state": "Missouri",
    "lat": 40.9973,
    "lng": -96.1606,
    "abbr": "MO"
  },
  {
    "city": "Lee's Summit",
    "state": "Missouri",
    "lat": 40.9973,
    "lng": -96.1606,
    "abbr": "MO"
  },
  {
    "city": "St. Joseph",
    "state": "Missouri",
    "lat": 40.9973,
    "lng": -96.1606,
    "abbr": "MO"
  },
  {
    "city": "St. Charles",
    "state": "Missouri",
    "lat": 40.9973,
    "lng": -96.1606,
    "abbr": "MO"
  },
  {
    "city": "St. Peters",
    "state": "Missouri",
    "lat": 40.9973,
    "lng": -96.1606,
    "abbr": "MO"
  },
  {
    "city": "Newton",
    "state": "Massachusetts",
    "lat": 41.0535,
    "lng": -74.7527,
    "abbr": "MA"
  },
  {
    "city": "Fort Wayne",
    "state": "Indiana",
    "lat": 41.0885,
    "lng": -85.1436,
    "abbr": "IN"
  },
  {
    "city": "New Bedford",
    "state": "Massachusetts",
    "lat": 41.0955,
    "lng": -80.4953,
    "abbr": "MA"
  },
  {
    "city": "Surprise",
    "state": "Arizona",
    "lat": 41.1047,
    "lng": -97.3086,
    "abbr": "AZ"
  },
  {
    "city": "Rockville",
    "state": "Maryland",
    "lat": 41.1184,
    "lng": -98.831,
    "abbr": "MD"
  },
  {
    "city": "Montebello",
    "state": "California",
    "lat": 41.1316,
    "lng": -74.1134,
    "abbr": "CA"
  },
  {
    "city": "Cheyenne",
    "state": "Wyoming",
    "lat": 41.1418,
    "lng": -104.7943,
    "abbr": "WY"
  },
  {
    "city": "Cuyahoga Falls",
    "state": "Ohio",
    "lat": 41.1641,
    "lng": -81.5206,
    "abbr": "OH"
  },
  {
    "city": "Vista",
    "state": "California",
    "lat": 41.2023,
    "lng": -73.5101,
    "abbr": "CA"
  },
  {
    "city": "Council Bluffs",
    "state": "Iowa",
    "lat": 41.2369,
    "lng": -95.8518,
    "abbr": "IA"
  },
  {
    "city": "Wilkes-Barre",
    "state": "Pennsylvania",
    "lat": 41.2468,
    "lng": -75.8759,
    "abbr": "PA"
  },
  {
    "city": "Evanston",
    "state": "Illinois",
    "lat": 41.2602,
    "lng": -110.9646,
    "abbr": "IL"
  },
  {
    "city": "West Haven",
    "state": "Connecticut",
    "lat": 41.2738,
    "lng": -72.9669,
    "abbr": "CT"
  },
  {
    "city": "Centennial",
    "state": "Colorado",
    "lat": 41.3005,
    "lng": -106.1355,
    "abbr": "CO"
  },
  {
    "city": "Strongsville",
    "state": "Ohio",
    "lat": 41.3128,
    "lng": -81.8313,
    "abbr": "OH"
  },
  {
    "city": "Berwyn",
    "state": "Illinois",
    "lat": 41.3508,
    "lng": -99.5006,
    "abbr": "IL"
  },
  {
    "city": "Westerville",
    "state": "Ohio",
    "lat": 41.3962,
    "lng": -99.3817,
    "abbr": "OH"
  },
  {
    "city": "Grand Rapids",
    "state": "Michigan",
    "lat": 41.4078,
    "lng": -83.8679,
    "abbr": "MI"
  },
  {
    "city": "Inglewood",
    "state": "California",
    "lat": 41.416,
    "lng": -96.5021,
    "abbr": "CA"
  },
  {
    "city": "Moline",
    "state": "Illinois",
    "lat": 41.4825,
    "lng": -90.4917,
    "abbr": "IL"
  },
  {
    "city": "Cleveland Heights",
    "state": "Ohio",
    "lat": 41.5112,
    "lng": -81.5636,
    "abbr": "OH"
  },
  {
    "city": "Joliet",
    "state": "Illinois",
    "lat": 41.5201,
    "lng": -88.1503,
    "abbr": "IL"
  },
  {
    "city": "Meriden",
    "state": "Connecticut",
    "lat": 41.5436,
    "lng": -104.3191,
    "abbr": "CT"
  },
  {
    "city": "Greeley",
    "state": "Colorado",
    "lat": 41.5486,
    "lng": -98.5312,
    "abbr": "CO"
  },
  {
    "city": "West Des Moines",
    "state": "Iowa",
    "lat": 41.5527,
    "lng": -93.7803,
    "abbr": "IA"
  },
  {
    "city": "Cedar Rapids",
    "state": "Iowa",
    "lat": 41.5589,
    "lng": -98.1493,
    "abbr": "IA"
  },
  {
    "city": "Tinley Park",
    "state": "Illinois",
    "lat": 41.5671,
    "lng": -87.8049,
    "abbr": "IL"
  },
  {
    "city": "Euclid",
    "state": "Ohio",
    "lat": 41.5904,
    "lng": -81.5188,
    "abbr": "OH"
  },
  {
    "city": "Orland Park",
    "state": "Illinois",
    "lat": 41.6075,
    "lng": -87.8618,
    "abbr": "IL"
  },
  {
    "city": "Calumet City",
    "state": "Illinois",
    "lat": 41.6133,
    "lng": -87.5502,
    "abbr": "IL"
  },
  {
    "city": "Romeoville",
    "state": "Illinois",
    "lat": 41.631,
    "lng": -88.1014,
    "abbr": "IL"
  },
  {
    "city": "Urbandale",
    "state": "Iowa",
    "lat": 41.639,
    "lng": -93.7814,
    "abbr": "IA"
  },
  {
    "city": "Iowa City",
    "state": "Iowa",
    "lat": 41.6558,
    "lng": -91.5304,
    "abbr": "IA"
  },
  {
    "city": "Mishawaka",
    "state": "Indiana",
    "lat": 41.6733,
    "lng": -86.1667,
    "abbr": "IN"
  },
  {
    "city": "Elyria",
    "state": "Ohio",
    "lat": 41.6802,
    "lng": -99.0053,
    "abbr": "OH"
  },
  {
    "city": "Bolingbrook",
    "state": "Illinois",
    "lat": 41.6911,
    "lng": -88.1012,
    "abbr": "IL"
  },
  {
    "city": "Oak Lawn",
    "state": "Illinois",
    "lat": 41.7139,
    "lng": -87.7528,
    "abbr": "IL"
  },
  {
    "city": "Ankeny",
    "state": "Iowa",
    "lat": 41.7279,
    "lng": -93.6033,
    "abbr": "IA"
  },
  {
    "city": "Naperville",
    "state": "Illinois",
    "lat": 41.7483,
    "lng": -88.1656,
    "abbr": "IL"
  },
  {
    "city": "Cranston",
    "state": "Rhode Island",
    "lat": 41.7658,
    "lng": -71.4858,
    "abbr": "RI"
  },
  {
    "city": "Jeffersonville",
    "state": "Indiana",
    "lat": 41.7799,
    "lng": -74.9298,
    "abbr": "IN"
  },
  {
    "city": "Downers Grove",
    "state": "Illinois",
    "lat": 41.7947,
    "lng": -88.017,
    "abbr": "IL"
  },
  {
    "city": "East Providence",
    "state": "Rhode Island",
    "lat": 41.8064,
    "lng": -71.3565,
    "abbr": "RI"
  },
  {
    "city": "Chicago",
    "state": "Illinois",
    "lat": 41.8373,
    "lng": -87.6861,
    "abbr": "IL"
  },
  {
    "city": "Cicero",
    "state": "Illinois",
    "lat": 41.8445,
    "lng": -87.7593,
    "abbr": "IL"
  },
  {
    "city": "Wheaton",
    "state": "Illinois",
    "lat": 41.856,
    "lng": -88.1083,
    "abbr": "IL"
  },
  {
    "city": "Lombard",
    "state": "Illinois",
    "lat": 41.8739,
    "lng": -88.0158,
    "abbr": "IL"
  },
  {
    "city": "Pawtucket",
    "state": "Rhode Island",
    "lat": 41.8743,
    "lng": -71.3744,
    "abbr": "RI"
  },
  {
    "city": "Coon Rapids",
    "state": "Minnesota",
    "lat": 41.8748,
    "lng": -94.6785,
    "abbr": "MN"
  },
  {
    "city": "Elmhurst",
    "state": "Illinois",
    "lat": 41.8973,
    "lng": -87.9432,
    "abbr": "IL"
  },
  {
    "city": "Taunton",
    "state": "Massachusetts",
    "lat": 41.9036,
    "lng": -71.0943,
    "abbr": "MA"
  },
  {
    "city": "Carol Stream",
    "state": "Illinois",
    "lat": 41.9183,
    "lng": -88.1309,
    "abbr": "IL"
  },
  {
    "city": "Moorhead",
    "state": "Minnesota",
    "lat": 41.9244,
    "lng": -95.8507,
    "abbr": "MN"
  },
  {
    "city": "DeKalb",
    "state": "Illinois",
    "lat": 41.9299,
    "lng": -88.7478,
    "abbr": "IL"
  },
  {
    "city": "Attleboro",
    "state": "Massachusetts",
    "lat": 41.9311,
    "lng": -71.295,
    "abbr": "MA"
  },
  {
    "city": "Hanover Park",
    "state": "Illinois",
    "lat": 41.9819,
    "lng": -88.1447,
    "abbr": "IL"
  },
  {
    "city": "Battle Creek",
    "state": "Michigan",
    "lat": 41.9981,
    "lng": -97.5994,
    "abbr": "MI"
  },
  {
    "city": "Woonsocket",
    "state": "Rhode Island",
    "lat": 42.001,
    "lng": -71.4993,
    "abbr": "RI"
  },
  {
    "city": "Streamwood",
    "state": "Illinois",
    "lat": 42.0209,
    "lng": -88.1778,
    "abbr": "IL"
  },
  {
    "city": "Schaumburg",
    "state": "Illinois",
    "lat": 42.0307,
    "lng": -88.0838,
    "abbr": "IL"
  },
  {
    "city": "Des Plaines",
    "state": "Illinois",
    "lat": 42.0343,
    "lng": -87.9008,
    "abbr": "IL"
  },
  {
    "city": "Skokie",
    "state": "Illinois",
    "lat": 42.036,
    "lng": -87.74,
    "abbr": "IL"
  },
  {
    "city": "Hoffman Estates",
    "state": "Illinois",
    "lat": 42.0638,
    "lng": -88.1463,
    "abbr": "IL"
  },
  {
    "city": "Mount Prospect",
    "state": "Illinois",
    "lat": 42.0653,
    "lng": -87.937,
    "abbr": "IL"
  },
  {
    "city": "Brockton",
    "state": "Massachusetts",
    "lat": 42.0821,
    "lng": -71.0242,
    "abbr": "MA"
  },
  {
    "city": "Binghamton",
    "state": "New York",
    "lat": 42.1014,
    "lng": -75.9092,
    "abbr": "NY"
  },
  {
    "city": "Palatine",
    "state": "Illinois",
    "lat": 42.1181,
    "lng": -88.043,
    "abbr": "IL"
  },
  {
    "city": "Carpentersville",
    "state": "Illinois",
    "lat": 42.1227,
    "lng": -88.2894,
    "abbr": "IL"
  },
  {
    "city": "Buffalo Grove",
    "state": "Illinois",
    "lat": 42.1673,
    "lng": -87.9615,
    "abbr": "IL"
  },
  {
    "city": "Kalamazoo",
    "state": "Michigan",
    "lat": 42.2749,
    "lng": -85.5882,
    "abbr": "MI"
  },
  {
    "city": "Ann Arbor",
    "state": "Michigan",
    "lat": 42.2757,
    "lng": -83.7312,
    "abbr": "MI"
  },
  {
    "city": "Dearborn Heights",
    "state": "Michigan",
    "lat": 42.3164,
    "lng": -83.2769,
    "abbr": "MI"
  },
  {
    "city": "Waukegan",
    "state": "Illinois",
    "lat": 42.3697,
    "lng": -87.8716,
    "abbr": "IL"
  },
  {
    "city": "Waltham",
    "state": "Massachusetts",
    "lat": 42.3889,
    "lng": -71.2423,
    "abbr": "MA"
  },
  {
    "city": "Revere",
    "state": "Massachusetts",
    "lat": 42.4188,
    "lng": -71.004,
    "abbr": "MA"
  },
  {
    "city": "Waterbury",
    "state": "Connecticut",
    "lat": 42.4571,
    "lng": -96.7353,
    "abbr": "CT"
  },
  {
    "city": "Southfield",
    "state": "Michigan",
    "lat": 42.4765,
    "lng": -83.2605,
    "abbr": "MI"
  },
  {
    "city": "Novi",
    "state": "Michigan",
    "lat": 42.4786,
    "lng": -83.4893,
    "abbr": "MI"
  },
  {
    "city": "Farmington Hills",
    "state": "Michigan",
    "lat": 42.486,
    "lng": -83.3771,
    "abbr": "MI"
  },
  {
    "city": "Woburn",
    "state": "Massachusetts",
    "lat": 42.4868,
    "lng": -71.1543,
    "abbr": "MA"
  },
  {
    "city": "Sioux City",
    "state": "Iowa",
    "lat": 42.4959,
    "lng": -96.3901,
    "abbr": "IA"
  },
  {
    "city": "Dubuque",
    "state": "Iowa",
    "lat": 42.5007,
    "lng": -90.7064,
    "abbr": "IA"
  },
  {
    "city": "Atlantic City",
    "state": "New Jersey",
    "lat": 42.5012,
    "lng": -108.733,
    "abbr": "NJ"
  },
  {
    "city": "Leominster",
    "state": "Massachusetts",
    "lat": 42.5209,
    "lng": -71.7717,
    "abbr": "MA"
  },
  {
    "city": "Beloit",
    "state": "Wisconsin",
    "lat": 42.523,
    "lng": -89.0184,
    "abbr": "WI"
  },
  {
    "city": "Norwich",
    "state": "Connecticut",
    "lat": 42.5333,
    "lng": -75.5227,
    "abbr": "CT"
  },
  {
    "city": "Peabody",
    "state": "Massachusetts",
    "lat": 42.5335,
    "lng": -70.9724,
    "abbr": "MA"
  },
  {
    "city": "Fontana",
    "state": "California",
    "lat": 42.5514,
    "lng": -88.5751,
    "abbr": "CA"
  },
  {
    "city": "Twin Falls",
    "state": "Idaho",
    "lat": 42.5631,
    "lng": -114.4637,
    "abbr": "ID"
  },
  {
    "city": "Sterling Heights",
    "state": "Michigan",
    "lat": 42.581,
    "lng": -83.0304,
    "abbr": "MI"
  },
  {
    "city": "Kenosha",
    "state": "Wisconsin",
    "lat": 42.5861,
    "lng": -87.8755,
    "abbr": "WI"
  },
  {
    "city": "Worcester",
    "state": "Massachusetts",
    "lat": 42.6018,
    "lng": -74.749,
    "abbr": "MA"
  },
  {
    "city": "Rochester Hills",
    "state": "Michigan",
    "lat": 42.6645,
    "lng": -83.1563,
    "abbr": "MI"
  },
  {
    "city": "Janesville",
    "state": "Wisconsin",
    "lat": 42.6855,
    "lng": -89.0137,
    "abbr": "WI"
  },
  {
    "city": "Albany",
    "state": "Georgia",
    "lat": 42.7069,
    "lng": -89.4367,
    "abbr": "GA"
  },
  {
    "city": "Albany",
    "state": "New York",
    "lat": 42.7069,
    "lng": -89.4367,
    "abbr": "NY"
  },
  {
    "city": "Albany",
    "state": "Oregon",
    "lat": 42.7069,
    "lng": -89.4367,
    "abbr": "OR"
  },
  {
    "city": "Mount Pleasant",
    "state": "South Carolina",
    "lat": 42.7129,
    "lng": -87.8875,
    "abbr": "SC"
  },
  {
    "city": "Racine",
    "state": "Wisconsin",
    "lat": 42.7274,
    "lng": -87.8135,
    "abbr": "WI"
  },
  {
    "city": "Methuen",
    "state": "Massachusetts",
    "lat": 42.734,
    "lng": -71.1889,
    "abbr": "MA"
  },
  {
    "city": "Boulder",
    "state": "Colorado",
    "lat": 42.7461,
    "lng": -109.7067,
    "abbr": "CO"
  },
  {
    "city": "East Lansing",
    "state": "Michigan",
    "lat": 42.7483,
    "lng": -84.4834,
    "abbr": "MI"
  },
  {
    "city": "Nashua",
    "state": "New Hampshire",
    "lat": 42.7492,
    "lng": -71.491,
    "abbr": "NH"
  },
  {
    "city": "Evansville",
    "state": "Indiana",
    "lat": 42.7781,
    "lng": -89.2967,
    "abbr": "IN"
  },
  {
    "city": "Schenectady",
    "state": "New York",
    "lat": 42.8025,
    "lng": -73.9276,
    "abbr": "NY"
  },
  {
    "city": "Troy",
    "state": "Michigan",
    "lat": 42.8258,
    "lng": -72.1828,
    "abbr": "MI"
  },
  {
    "city": "Troy",
    "state": "New York",
    "lat": 42.8258,
    "lng": -72.1828,
    "abbr": "NY"
  },
  {
    "city": "Lakeville",
    "state": "Minnesota",
    "lat": 42.8367,
    "lng": -77.7032,
    "abbr": "MN"
  },
  {
    "city": "Santee",
    "state": "California",
    "lat": 42.8388,
    "lng": -97.8491,
    "abbr": "CA"
  },
  {
    "city": "Casper",
    "state": "Wyoming",
    "lat": 42.843,
    "lng": -106.3205,
    "abbr": "WY"
  },
  {
    "city": "Belleville",
    "state": "Illinois",
    "lat": 42.8643,
    "lng": -89.5402,
    "abbr": "IL"
  },
  {
    "city": "Pocatello",
    "state": "Idaho",
    "lat": 42.8716,
    "lng": -112.466,
    "abbr": "ID"
  },
  {
    "city": "Bloomington",
    "state": "Illinois",
    "lat": 42.8929,
    "lng": -90.9259,
    "abbr": "IL"
  },
  {
    "city": "Bloomington",
    "state": "Indiana",
    "lat": 42.8929,
    "lng": -90.9259,
    "abbr": "IN"
  },
  {
    "city": "Bloomington",
    "state": "Minnesota",
    "lat": 42.8929,
    "lng": -90.9259,
    "abbr": "MN"
  },
  {
    "city": "Marlborough",
    "state": "Massachusetts",
    "lat": 42.905,
    "lng": -72.2116,
    "abbr": "MA"
  },
  {
    "city": "Lima",
    "state": "Ohio",
    "lat": 42.9069,
    "lng": -77.6123,
    "abbr": "OH"
  },
  {
    "city": "Linden",
    "state": "New Jersey",
    "lat": 42.9185,
    "lng": -90.2742,
    "abbr": "NJ"
  },
  {
    "city": "Stillwater",
    "state": "Oklahoma",
    "lat": 42.9467,
    "lng": -73.6441,
    "abbr": "OK"
  },
  {
    "city": "Greenfield",
    "state": "Wisconsin",
    "lat": 42.9619,
    "lng": -88.005,
    "abbr": "WI"
  },
  {
    "city": "New Berlin",
    "state": "Wisconsin",
    "lat": 42.9726,
    "lng": -88.1292,
    "abbr": "WI"
  },
  {
    "city": "Fitchburg",
    "state": "Massachusetts",
    "lat": 42.9859,
    "lng": -89.4255,
    "abbr": "MA"
  },
  {
    "city": "West Allis",
    "state": "Wisconsin",
    "lat": 43.0068,
    "lng": -88.0296,
    "abbr": "WI"
  },
  {
    "city": "Cambridge",
    "state": "Massachusetts",
    "lat": 43.0071,
    "lng": -89.0214,
    "abbr": "MA"
  },
  {
    "city": "Waukesha",
    "state": "Wisconsin",
    "lat": 43.0088,
    "lng": -88.2462,
    "abbr": "WI"
  },
  {
    "city": "Akron",
    "state": "Ohio",
    "lat": 43.0179,
    "lng": -78.4978,
    "abbr": "OH"
  },
  {
    "city": "Utica",
    "state": "New York",
    "lat": 43.0222,
    "lng": -88.4848,
    "abbr": "NY"
  },
  {
    "city": "Highland",
    "state": "California",
    "lat": 43.0475,
    "lng": -90.3802,
    "abbr": "CA"
  },
  {
    "city": "Wauwatosa",
    "state": "Wisconsin",
    "lat": 43.0616,
    "lng": -88.0347,
    "abbr": "WI"
  },
  {
    "city": "Milwaukee",
    "state": "Wisconsin",
    "lat": 43.064,
    "lng": -87.9669,
    "abbr": "WI"
  },
  {
    "city": "Brookfield",
    "state": "Wisconsin",
    "lat": 43.064,
    "lng": -88.1231,
    "abbr": "WI"
  },
  {
    "city": "Niagara Falls",
    "state": "New York",
    "lat": 43.0921,
    "lng": -79.0147,
    "abbr": "NY"
  },
  {
    "city": "Shawnee",
    "state": "Kansas",
    "lat": 43.1281,
    "lng": -78.8378,
    "abbr": "KS"
  },
  {
    "city": "Durham",
    "state": "North Carolina",
    "lat": 43.1413,
    "lng": -70.9228,
    "abbr": "NC"
  },
  {
    "city": "Waterloo",
    "state": "Iowa",
    "lat": 43.1835,
    "lng": -88.99,
    "abbr": "IA"
  },
  {
    "city": "Muskegon",
    "state": "Michigan",
    "lat": 43.228,
    "lng": -86.256,
    "abbr": "MI"
  },
  {
    "city": "Germantown",
    "state": "Tennessee",
    "lat": 43.2343,
    "lng": -88.1217,
    "abbr": "TN"
  },
  {
    "city": "Pittsfield",
    "state": "Massachusetts",
    "lat": 43.3001,
    "lng": -71.3314,
    "abbr": "MA"
  },
  {
    "city": "Lodi",
    "state": "California",
    "lat": 43.3153,
    "lng": -89.5378,
    "abbr": "CA"
  },
  {
    "city": "Hartford",
    "state": "Connecticut",
    "lat": 43.3215,
    "lng": -88.3777,
    "abbr": "CT"
  },
  {
    "city": "Jackson",
    "state": "Mississippi",
    "lat": 43.3244,
    "lng": -88.1668,
    "abbr": "MS"
  },
  {
    "city": "Jackson",
    "state": "Tennessee",
    "lat": 43.3244,
    "lng": -88.1668,
    "abbr": "TN"
  },
  {
    "city": "Columbus",
    "state": "Georgia",
    "lat": 43.3354,
    "lng": -89.0299,
    "abbr": "GA"
  },
  {
    "city": "Columbus",
    "state": "Indiana",
    "lat": 43.3354,
    "lng": -89.0299,
    "abbr": "IN"
  },
  {
    "city": "Columbus",
    "state": "Ohio",
    "lat": 43.3354,
    "lng": -89.0299,
    "abbr": "OH"
  },
  {
    "city": "Lowell",
    "state": "Massachusetts",
    "lat": 43.3363,
    "lng": -88.8184,
    "abbr": "MA"
  },
  {
    "city": "Fall River",
    "state": "Massachusetts",
    "lat": 43.3856,
    "lng": -89.0454,
    "abbr": "MA"
  },
  {
    "city": "Idaho Falls",
    "state": "Idaho",
    "lat": 43.4878,
    "lng": -112.0359,
    "abbr": "ID"
  },
  {
    "city": "Sioux Falls",
    "state": "South Dakota",
    "lat": 43.5382,
    "lng": -96.7315,
    "abbr": "SD"
  },
  {
    "city": "Nampa",
    "state": "Idaho",
    "lat": 43.5833,
    "lng": -116.5641,
    "abbr": "ID"
  },
  {
    "city": "Ontario",
    "state": "California",
    "lat": 43.7222,
    "lng": -90.5941,
    "abbr": "CA"
  },
  {
    "city": "Sheboygan",
    "state": "Wisconsin",
    "lat": 43.7448,
    "lng": -87.7317,
    "abbr": "WI"
  },
  {
    "city": "Fond du Lac",
    "state": "Wisconsin",
    "lat": 43.7719,
    "lng": -88.4396,
    "abbr": "WI"
  },
  {
    "city": "Parma",
    "state": "Ohio",
    "lat": 43.7864,
    "lng": -116.9429,
    "abbr": "OH"
  },
  {
    "city": "Norwalk",
    "state": "California",
    "lat": 43.8342,
    "lng": -90.6269,
    "abbr": "CA"
  },
  {
    "city": "Norwalk",
    "state": "Connecticut",
    "lat": 43.8342,
    "lng": -90.6269,
    "abbr": "CT"
  },
  {
    "city": "Westfield",
    "state": "Massachusetts",
    "lat": 43.8851,
    "lng": -89.4927,
    "abbr": "MA"
  },
  {
    "city": "Oshkosh",
    "state": "Wisconsin",
    "lat": 44.0242,
    "lng": -88.561,
    "abbr": "WI"
  },
  {
    "city": "Bartlett",
    "state": "Illinois",
    "lat": 44.0734,
    "lng": -71.282,
    "abbr": "IL"
  },
  {
    "city": "Bartlett",
    "state": "Tennessee",
    "lat": 44.0734,
    "lng": -71.282,
    "abbr": "TN"
  },
  {
    "city": "Tustin",
    "state": "California",
    "lat": 44.1695,
    "lng": -88.8963,
    "abbr": "CA"
  },
  {
    "city": "Mankato",
    "state": "Minnesota",
    "lat": 44.172,
    "lng": -93.9782,
    "abbr": "MN"
  },
  {
    "city": "Plainfield",
    "state": "Illinois",
    "lat": 44.2178,
    "lng": -89.4973,
    "abbr": "IL"
  },
  {
    "city": "Plainfield",
    "state": "New Jersey",
    "lat": 44.2178,
    "lng": -89.4973,
    "abbr": "NJ"
  },
  {
    "city": "Arcadia",
    "state": "California",
    "lat": 44.2494,
    "lng": -91.4948,
    "abbr": "CA"
  },
  {
    "city": "Fremont",
    "state": "California",
    "lat": 44.2602,
    "lng": -88.8715,
    "abbr": "CA"
  },
  {
    "city": "Appleton",
    "state": "Wisconsin",
    "lat": 44.2775,
    "lng": -88.3896,
    "abbr": "WI"
  },
  {
    "city": "Taylor",
    "state": "Michigan",
    "lat": 44.3217,
    "lng": -91.1205,
    "abbr": "MI"
  },
  {
    "city": "Green Bay",
    "state": "Wisconsin",
    "lat": 44.5151,
    "lng": -87.9895,
    "abbr": "WI"
  },
  {
    "city": "Park Ridge",
    "state": "Illinois",
    "lat": 44.52,
    "lng": -89.5463,
    "abbr": "IL"
  },
  {
    "city": "Arvada",
    "state": "Colorado",
    "lat": 44.6622,
    "lng": -106.1419,
    "abbr": "CO"
  },
  {
    "city": "Shakopee",
    "state": "Minnesota",
    "lat": 44.7745,
    "lng": -93.4763,
    "abbr": "MN"
  },
  {
    "city": "Garland",
    "state": "Texas",
    "lat": 44.7777,
    "lng": -108.6542,
    "abbr": "TX"
  },
  {
    "city": "Eau Claire",
    "state": "Wisconsin",
    "lat": 44.8202,
    "lng": -91.4948,
    "abbr": "WI"
  },
  {
    "city": "Rapid City",
    "state": "South Dakota",
    "lat": 44.8366,
    "lng": -85.2871,
    "abbr": "SD"
  },
  {
    "city": "Gresham",
    "state": "Oregon",
    "lat": 44.8476,
    "lng": -88.7849,
    "abbr": "OR"
  },
  {
    "city": "Eden Prairie",
    "state": "Minnesota",
    "lat": 44.8487,
    "lng": -93.4595,
    "abbr": "MN"
  },
  {
    "city": "Weston",
    "state": "Florida",
    "lat": 44.8905,
    "lng": -89.5488,
    "abbr": "FL"
  },
  {
    "city": "Minnetonka",
    "state": "Minnesota",
    "lat": 44.9324,
    "lng": -93.4601,
    "abbr": "MN"
  },
  {
    "city": "Wilson",
    "state": "North Carolina",
    "lat": 44.9582,
    "lng": -92.1706,
    "abbr": "NC"
  },
  {
    "city": "Wausau",
    "state": "Wisconsin",
    "lat": 44.9616,
    "lng": -89.6439,
    "abbr": "WI"
  },
  {
    "city": "Minneapolis",
    "state": "Minnesota",
    "lat": 44.9635,
    "lng": -93.2679,
    "abbr": "MN"
  },
  {
    "city": "Hammond",
    "state": "Indiana",
    "lat": 44.9687,
    "lng": -92.4379,
    "abbr": "IN"
  },
  {
    "city": "Keizer",
    "state": "Oregon",
    "lat": 45.0028,
    "lng": -123.0242,
    "abbr": "OR"
  },
  {
    "city": "Athens-Clarke County",
    "state": "Georgia",
    "lat": 45.0346,
    "lng": -90.0791,
    "abbr": "GA"
  },
  {
    "city": "Maple Grove",
    "state": "Minnesota",
    "lat": 45.1088,
    "lng": -93.4626,
    "abbr": "MN"
  },
  {
    "city": "Medford",
    "state": "Massachusetts",
    "lat": 45.1364,
    "lng": -90.3437,
    "abbr": "MA"
  },
  {
    "city": "Medford",
    "state": "Oregon",
    "lat": 45.1364,
    "lng": -90.3437,
    "abbr": "OR"
  },
  {
    "city": "Dallas",
    "state": "Texas",
    "lat": 45.259,
    "lng": -91.8142,
    "abbr": "TX"
  },
  {
    "city": "Chelsea",
    "state": "Massachusetts",
    "lat": 45.2926,
    "lng": -90.3054,
    "abbr": "MA"
  },
  {
    "city": "Lake Oswego",
    "state": "Oregon",
    "lat": 45.4129,
    "lng": -122.7002,
    "abbr": "OR"
  },
  {
    "city": "Tigard",
    "state": "Oregon",
    "lat": 45.4243,
    "lng": -122.7827,
    "abbr": "OR"
  },
  {
    "city": "Canton",
    "state": "Ohio",
    "lat": 45.428,
    "lng": -91.6599,
    "abbr": "OH"
  },
  {
    "city": "Vancouver",
    "state": "Washington",
    "lat": 45.6352,
    "lng": -122.5972,
    "abbr": "WA"
  },
  {
    "city": "Bozeman",
    "state": "Montana",
    "lat": 45.6828,
    "lng": -111.0545,
    "abbr": "MT"
  },
  {
    "city": "Carson",
    "state": "California",
    "lat": 45.7353,
    "lng": -121.8219,
    "abbr": "CA"
  },
  {
    "city": "Lewisville",
    "state": "Texas",
    "lat": 45.8124,
    "lng": -122.5146,
    "abbr": "TX"
  },
  {
    "city": "Woodland",
    "state": "California",
    "lat": 45.9147,
    "lng": -122.7503,
    "abbr": "CA"
  },
  {
    "city": "Florence",
    "state": "Alabama",
    "lat": 45.9251,
    "lng": -88.2525,
    "abbr": "AL"
  },
  {
    "city": "Florence",
    "state": "South Carolina",
    "lat": 45.9251,
    "lng": -88.2525,
    "abbr": "SC"
  },
  {
    "city": "Plymouth",
    "state": "Minnesota",
    "lat": 45.9346,
    "lng": -119.3503,
    "abbr": "MN"
  },
  {
    "city": "Paterson",
    "state": "New Jersey",
    "lat": 45.9371,
    "lng": -119.6028,
    "abbr": "NJ"
  },
  {
    "city": "Danbury",
    "state": "Connecticut",
    "lat": 46.0088,
    "lng": -92.3777,
    "abbr": "CT"
  },
  {
    "city": "Hayward",
    "state": "California",
    "lat": 46.0094,
    "lng": -91.4826,
    "abbr": "CA"
  },
  {
    "city": "Scranton",
    "state": "Pennsylvania",
    "lat": 46.1467,
    "lng": -103.1427,
    "abbr": "PA"
  },
  {
    "city": "Longview",
    "state": "Texas",
    "lat": 46.1471,
    "lng": -122.9642,
    "abbr": "TX"
  },
  {
    "city": "Kennewick",
    "state": "Washington",
    "lat": 46.1979,
    "lng": -119.1732,
    "abbr": "WA"
  },
  {
    "city": "Burbank",
    "state": "California",
    "lat": 46.1991,
    "lng": -118.9728,
    "abbr": "CA"
  },
  {
    "city": "Pasco",
    "state": "Washington",
    "lat": 46.2499,
    "lng": -119.1271,
    "abbr": "WA"
  },
  {
    "city": "Altoona",
    "state": "Pennsylvania",
    "lat": 46.2699,
    "lng": -123.6145,
    "abbr": "PA"
  },
  {
    "city": "Castle Rock",
    "state": "Colorado",
    "lat": 46.2717,
    "lng": -122.9056,
    "abbr": "CO"
  },
  {
    "city": "Richland",
    "state": "Washington",
    "lat": 46.2828,
    "lng": -119.2939,
    "abbr": "WA"
  },
  {
    "city": "Huntsville",
    "state": "Alabama",
    "lat": 46.2871,
    "lng": -118.1072,
    "abbr": "AL"
  },
  {
    "city": "Huntsville",
    "state": "Texas",
    "lat": 46.2871,
    "lng": -118.1072,
    "abbr": "TX"
  },
  {
    "city": "Prescott",
    "state": "Arizona",
    "lat": 46.2989,
    "lng": -118.3126,
    "abbr": "AZ"
  },
  {
    "city": "Corvallis",
    "state": "Oregon",
    "lat": 46.3139,
    "lng": -114.1123,
    "abbr": "OR"
  },
  {
    "city": "Dayton",
    "state": "Ohio",
    "lat": 46.3171,
    "lng": -117.9768,
    "abbr": "OH"
  },
  {
    "city": "Long Beach",
    "state": "California",
    "lat": 46.3555,
    "lng": -124.0557,
    "abbr": "CA"
  },
  {
    "city": "Toledo",
    "state": "Ohio",
    "lat": 46.4412,
    "lng": -122.8494,
    "abbr": "OH"
  },
  {
    "city": "Parker",
    "state": "Colorado",
    "lat": 46.5022,
    "lng": -120.4668,
    "abbr": "CO"
  },
  {
    "city": "Colton",
    "state": "California",
    "lat": 46.5677,
    "lng": -117.1284,
    "abbr": "CA"
  },
  {
    "city": "Mesa",
    "state": "Arizona",
    "lat": 46.5736,
    "lng": -119.0013,
    "abbr": "AZ"
  },
  {
    "city": "Yakima",
    "state": "Washington",
    "lat": 46.5926,
    "lng": -120.5492,
    "abbr": "WA"
  },
  {
    "city": "South Bend",
    "state": "Indiana",
    "lat": 46.6683,
    "lng": -123.8016,
    "abbr": "IN"
  },
  {
    "city": "La Crosse",
    "state": "Wisconsin",
    "lat": 46.8027,
    "lng": -117.9014,
    "abbr": "WI"
  },
  {
    "city": "Bismarck",
    "state": "North Dakota",
    "lat": 46.8127,
    "lng": -100.7694,
    "abbr": "ND"
  },
  {
    "city": "Rochester",
    "state": "Minnesota",
    "lat": 46.8287,
    "lng": -123.0721,
    "abbr": "MN"
  },
  {
    "city": "Rochester",
    "state": "New York",
    "lat": 46.8287,
    "lng": -123.0721,
    "abbr": "NY"
  },
  {
    "city": "Beverly",
    "state": "Massachusetts",
    "lat": 46.8368,
    "lng": -119.9331,
    "abbr": "MA"
  },
  {
    "city": "Smyrna",
    "state": "Georgia",
    "lat": 46.8393,
    "lng": -119.6623,
    "abbr": "GA"
  },
  {
    "city": "Smyrna",
    "state": "Tennessee",
    "lat": 46.8393,
    "lng": -119.6623,
    "abbr": "TN"
  },
  {
    "city": "Fargo",
    "state": "North Dakota",
    "lat": 46.8654,
    "lng": -96.829,
    "abbr": "ND"
  },
  {
    "city": "Missoula",
    "state": "Montana",
    "lat": 46.8687,
    "lng": -114.0089,
    "abbr": "MT"
  },
  {
    "city": "Melbourne",
    "state": "Florida",
    "lat": 46.9454,
    "lng": -123.626,
    "abbr": "FL"
  },
  {
    "city": "Roy",
    "state": "Utah",
    "lat": 46.9971,
    "lng": -122.5424,
    "abbr": "UT"
  },
  {
    "city": "Olympia",
    "state": "Washington",
    "lat": 47.0417,
    "lng": -122.8958,
    "abbr": "WA"
  },
  {
    "city": "Lacey",
    "state": "Washington",
    "lat": 47.0444,
    "lng": -122.7933,
    "abbr": "WA"
  },
  {
    "city": "Farmington",
    "state": "New Mexico",
    "lat": 47.0887,
    "lng": -117.0464,
    "abbr": "NM"
  },
  {
    "city": "Erie",
    "state": "Pennsylvania",
    "lat": 47.1154,
    "lng": -97.3879,
    "abbr": "PA"
  },
  {
    "city": "Thornton",
    "state": "Colorado",
    "lat": 47.1207,
    "lng": -117.3896,
    "abbr": "CO"
  },
  {
    "city": "Lakewood",
    "state": "California",
    "lat": 47.1627,
    "lng": -122.5299,
    "abbr": "CA"
  },
  {
    "city": "Lakewood",
    "state": "Colorado",
    "lat": 47.1627,
    "lng": -122.5299,
    "abbr": "CO"
  },
  {
    "city": "Lakewood",
    "state": "Ohio",
    "lat": 47.1627,
    "lng": -122.5299,
    "abbr": "OH"
  },
  {
    "city": "Lakewood",
    "state": "Washington",
    "lat": 47.1627,
    "lng": -122.5299,
    "abbr": "WA"
  },
  {
    "city": "Midland",
    "state": "Michigan",
    "lat": 47.1734,
    "lng": -122.412,
    "abbr": "MI"
  },
  {
    "city": "Midland",
    "state": "Texas",
    "lat": 47.1734,
    "lng": -122.412,
    "abbr": "TX"
  },
  {
    "city": "Puyallup",
    "state": "Washington",
    "lat": 47.1794,
    "lng": -122.2901,
    "abbr": "WA"
  },
  {
    "city": "Shelton",
    "state": "Connecticut",
    "lat": 47.2186,
    "lng": -123.1128,
    "abbr": "CT"
  },
  {
    "city": "Malden",
    "state": "Massachusetts",
    "lat": 47.2296,
    "lng": -117.4732,
    "abbr": "MA"
  },
  {
    "city": "Quincy",
    "state": "Illinois",
    "lat": 47.2342,
    "lng": -119.8512,
    "abbr": "IL"
  },
  {
    "city": "Quincy",
    "state": "Massachusetts",
    "lat": 47.2342,
    "lng": -119.8512,
    "abbr": "MA"
  },
  {
    "city": "Tacoma",
    "state": "Washington",
    "lat": 47.2431,
    "lng": -122.4531,
    "abbr": "WA"
  },
  {
    "city": "Auburn",
    "state": "Alabama",
    "lat": 47.3039,
    "lng": -122.2108,
    "abbr": "AL"
  },
  {
    "city": "Auburn",
    "state": "Washington",
    "lat": 47.3039,
    "lng": -122.2108,
    "abbr": "WA"
  },
  {
    "city": "Federal Way",
    "state": "Washington",
    "lat": 47.3091,
    "lng": -122.3358,
    "abbr": "WA"
  },
  {
    "city": "Odessa",
    "state": "Texas",
    "lat": 47.3326,
    "lng": -118.6893,
    "abbr": "TX"
  },
  {
    "city": "Georgetown",
    "state": "Texas",
    "lat": 47.3576,
    "lng": -121.9737,
    "abbr": "TX"
  },
  {
    "city": "Covington",
    "state": "Kentucky",
    "lat": 47.3665,
    "lng": -122.105,
    "abbr": "KY"
  },
  {
    "city": "Maplewood",
    "state": "Minnesota",
    "lat": 47.3715,
    "lng": -122.5689,
    "abbr": "MN"
  },
  {
    "city": "Rock Island",
    "state": "Illinois",
    "lat": 47.3738,
    "lng": -120.1369,
    "abbr": "IL"
  },
  {
    "city": "Fairfield",
    "state": "California",
    "lat": 47.3848,
    "lng": -117.1748,
    "abbr": "CA"
  },
  {
    "city": "Fairfield",
    "state": "Ohio",
    "lat": 47.3848,
    "lng": -117.1748,
    "abbr": "OH"
  },
  {
    "city": "Kent",
    "state": "Washington",
    "lat": 47.3887,
    "lng": -122.2128,
    "abbr": "WA"
  },
  {
    "city": "Des Moines",
    "state": "Iowa",
    "lat": 47.3914,
    "lng": -122.3154,
    "abbr": "IA"
  },
  {
    "city": "Portage",
    "state": "Michigan",
    "lat": 47.4073,
    "lng": -122.4376,
    "abbr": "MI"
  },
  {
    "city": "St. Clair Shores",
    "state": "Michigan",
    "lat": 47.4073,
    "lng": -122.4376,
    "abbr": "MI"
  },
  {
    "city": "Cedar Falls",
    "state": "Iowa",
    "lat": 47.4254,
    "lng": -121.7768,
    "abbr": "IA"
  },
  {
    "city": "Tyler",
    "state": "Texas",
    "lat": 47.436,
    "lng": -117.7835,
    "abbr": "TX"
  },
  {
    "city": "Rockford",
    "state": "Illinois",
    "lat": 47.4512,
    "lng": -117.1309,
    "abbr": "IL"
  },
  {
    "city": "Renton",
    "state": "Washington",
    "lat": 47.4757,
    "lng": -122.1904,
    "abbr": "WA"
  },
  {
    "city": "Burien",
    "state": "Washington",
    "lat": 47.4761,
    "lng": -122.3393,
    "abbr": "WA"
  },
  {
    "city": "Allentown",
    "state": "Pennsylvania",
    "lat": 47.4943,
    "lng": -122.2762,
    "abbr": "PA"
  },
  {
    "city": "Bremerton",
    "state": "Washington",
    "lat": 47.5436,
    "lng": -122.7121,
    "abbr": "WA"
  },
  {
    "city": "Annapolis",
    "state": "Maryland",
    "lat": 47.5476,
    "lng": -122.6207,
    "abbr": "MD"
  },
  {
    "city": "Manchester",
    "state": "New Hampshire",
    "lat": 47.5519,
    "lng": -122.5517,
    "abbr": "NH"
  },
  {
    "city": "Bellevue",
    "state": "Nebraska",
    "lat": 47.5953,
    "lng": -122.155,
    "abbr": "NE"
  },
  {
    "city": "Bellevue",
    "state": "Washington",
    "lat": 47.5953,
    "lng": -122.155,
    "abbr": "WA"
  },
  {
    "city": "Sammamish",
    "state": "Washington",
    "lat": 47.6045,
    "lng": -122.0446,
    "abbr": "WA"
  },
  {
    "city": "Seattle",
    "state": "Washington",
    "lat": 47.6217,
    "lng": -122.3238,
    "abbr": "WA"
  },
  {
    "city": "Chico",
    "state": "California",
    "lat": 47.6234,
    "lng": -122.7198,
    "abbr": "CA"
  },
  {
    "city": "Brownsville",
    "state": "Texas",
    "lat": 47.6557,
    "lng": -122.6165,
    "abbr": "TX"
  },
  {
    "city": "Davenport",
    "state": "Iowa",
    "lat": 47.6562,
    "lng": -118.1519,
    "abbr": "IA"
  },
  {
    "city": "Spokane Valley",
    "state": "Washington",
    "lat": 47.6625,
    "lng": -117.2345,
    "abbr": "WA"
  },
  {
    "city": "Spokane",
    "state": "Washington",
    "lat": 47.6671,
    "lng": -117.433,
    "abbr": "WA"
  },
  {
    "city": "Meridian",
    "state": "Idaho",
    "lat": 47.6695,
    "lng": -122.3304,
    "abbr": "ID"
  },
  {
    "city": "Meridian",
    "state": "Mississippi",
    "lat": 47.6695,
    "lng": -122.3304,
    "abbr": "MS"
  },
  {
    "city": "Redmond",
    "state": "Washington",
    "lat": 47.6776,
    "lng": -122.1176,
    "abbr": "WA"
  },
  {
    "city": "Kirkland",
    "state": "Washington",
    "lat": 47.6996,
    "lng": -122.1982,
    "abbr": "WA"
  },
  {
    "city": "Coeur d'Alene",
    "state": "Idaho",
    "lat": 47.7041,
    "lng": -116.7928,
    "abbr": "ID"
  },
  {
    "city": "Shoreline",
    "state": "Washington",
    "lat": 47.7563,
    "lng": -122.3425,
    "abbr": "WA"
  },
  {
    "city": "Edmonds",
    "state": "Washington",
    "lat": 47.8114,
    "lng": -122.3534,
    "abbr": "WA"
  },
  {
    "city": "Mansfield",
    "state": "Ohio",
    "lat": 47.8118,
    "lng": -119.6384,
    "abbr": "OH"
  },
  {
    "city": "Mansfield",
    "state": "Texas",
    "lat": 47.8118,
    "lng": -119.6384,
    "abbr": "TX"
  },
  {
    "city": "Lincoln",
    "state": "California",
    "lat": 47.829,
    "lng": -118.415,
    "abbr": "CA"
  },
  {
    "city": "Lincoln",
    "state": "Nebraska",
    "lat": 47.829,
    "lng": -118.415,
    "abbr": "NE"
  },
  {
    "city": "Warwick",
    "state": "Rhode Island",
    "lat": 47.8547,
    "lng": -98.7057,
    "abbr": "RI"
  },
  {
    "city": "Monroe",
    "state": "Louisiana",
    "lat": 47.8594,
    "lng": -121.9854,
    "abbr": "LA"
  },
  {
    "city": "Grand Forks",
    "state": "North Dakota",
    "lat": 47.912,
    "lng": -97.0751,
    "abbr": "ND"
  },
  {
    "city": "Glendale",
    "state": "Arizona",
    "lat": 47.9395,
    "lng": -122.3585,
    "abbr": "AZ"
  },
  {
    "city": "Glendale",
    "state": "California",
    "lat": 47.9395,
    "lng": -122.3585,
    "abbr": "CA"
  },
  {
    "city": "Everett",
    "state": "Massachusetts",
    "lat": 47.9524,
    "lng": -122.1672,
    "abbr": "MA"
  },
  {
    "city": "Weymouth Town",
    "state": "Massachusetts",
    "lat": 47.9524,
    "lng": -122.1672,
    "abbr": "MA"
  },
  {
    "city": "Barnstable Town",
    "state": "Massachusetts",
    "lat": 47.9524,
    "lng": -122.1672,
    "abbr": "MA"
  },
  {
    "city": "Everett",
    "state": "Washington",
    "lat": 47.9524,
    "lng": -122.1672,
    "abbr": "WA"
  },
  {
    "city": "Bridgeport",
    "state": "Connecticut",
    "lat": 48.0062,
    "lng": -119.6732,
    "abbr": "CT"
  },
  {
    "city": "Camden",
    "state": "New Jersey",
    "lat": 48.0474,
    "lng": -117.2399,
    "abbr": "NJ"
  },
  {
    "city": "Springdale",
    "state": "Arkansas",
    "lat": 48.0572,
    "lng": -117.7466,
    "abbr": "AR"
  },
  {
    "city": "Marysville",
    "state": "Washington",
    "lat": 48.0812,
    "lng": -122.1559,
    "abbr": "WA"
  },
  {
    "city": "Keller",
    "state": "Texas",
    "lat": 48.0851,
    "lng": -118.7162,
    "abbr": "TX"
  },
  {
    "city": "Arlington",
    "state": "Texas",
    "lat": 48.1698,
    "lng": -122.144,
    "abbr": "TX"
  },
  {
    "city": "Arlington Heights",
    "state": "Illinois",
    "lat": 48.2119,
    "lng": -122.0631,
    "abbr": "IL"
  },
  {
    "city": "Minot",
    "state": "North Dakota",
    "lat": 48.2375,
    "lng": -101.2779,
    "abbr": "ND"
  },
  {
    "city": "Lawton",
    "state": "Oklahoma",
    "lat": 48.3036,
    "lng": -98.3685,
    "abbr": "OK"
  },
  {
    "city": "York",
    "state": "Pennsylvania",
    "lat": 48.3131,
    "lng": -99.5736,
    "abbr": "PA"
  },
  {
    "city": "Conway",
    "state": "Arkansas",
    "lat": 48.3356,
    "lng": -122.3444,
    "abbr": "AR"
  },
  {
    "city": "Mount Vernon",
    "state": "New York",
    "lat": 48.4203,
    "lng": -122.3114,
    "abbr": "NY"
  },
  {
    "city": "Richardson",
    "state": "Texas",
    "lat": 48.4515,
    "lng": -122.8988,
    "abbr": "TX"
  },
  {
    "city": "Burlington",
    "state": "North Carolina",
    "lat": 48.4676,
    "lng": -122.3298,
    "abbr": "NC"
  },
  {
    "city": "Burlington",
    "state": "Vermont",
    "lat": 48.4676,
    "lng": -122.3298,
    "abbr": "VT"
  },
  {
    "city": "Riverside",
    "state": "California",
    "lat": 48.5046,
    "lng": -119.5109,
    "abbr": "CA"
  },
  {
    "city": "Hamilton",
    "state": "Ohio",
    "lat": 48.5281,
    "lng": -121.9837,
    "abbr": "OH"
  },
  {
    "city": "Gardena",
    "state": "California",
    "lat": 48.7013,
    "lng": -100.4985,
    "abbr": "CA"
  },
  {
    "city": "Bellingham",
    "state": "Washington",
    "lat": 48.7534,
    "lng": -122.4683,
    "abbr": "WA"
  },
  {
    "city": "Blaine",
    "state": "Minnesota",
    "lat": 48.9839,
    "lng": -122.7406,
    "abbr": "MN"
  },
  {
    "city": "Danville",
    "state": "California",
    "lat": 48.9938,
    "lng": -118.5068,
    "abbr": "CA"
  },
  {
    "city": "San Buenaventura (Ventura)",
    "state": "California",
    "lat": 48.9938,
    "lng": -118.5068,
    "abbr": "CA"
  },
  {
    "city": "Jurupa Valley",
    "state": "California",
    "lat": 48.9938,
    "lng": -118.5068,
    "abbr": "CA"
  },
  {
    "city": "Danville",
    "state": "Virginia",
    "lat": 48.9938,
    "lng": -118.5068,
    "abbr": "VA"
  },
  {
    "city": "Anchorage",
    "state": "Alaska",
    "lat": 61.1508,
    "lng": -149.1091,
    "abbr": "AK"
  },
  {
    "city": "St. George",
    "state": "Utah",
    "lat": 40.6356,
    "lng": -112.3054,
    "abbr": "UT"
  }
]

export default data;